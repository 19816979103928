<template>
  <template
    v-for="(ruleset, i) in rulesets"
    :key="ruleset.mappingId"
  >
    <!-- This AND/OR bracket is for when there ARE existing restrictions -->
    <template v-if="clauses[i] && restrictions.length">
      <div
        class="p-2 and-or-container"
      >
        <ButtonGroup class="and-or-group">
          <CustomButton
            :purpose="clauses[i] === 'and' ? 'active' : 'inactive'"
            small
            @click="setClause('and', i)"
          >
            {{ t('a_n_d') }}
          </CustomButton>
          <CustomButton
            :purpose="clauses[i] === 'or' ? 'active' : 'inactive'"
            small
            @click="setClause('or', i)"
          >
            {{ t('o_r') }}
          </CustomButton>
        </ButtonGroup>
        <CustomButton
          v-if="i > 0"
          icon-color="var(--colour-utility-white)"
          icon-name="trash"
          round
          icon-only
          @click="deleteClause(i)"
        />
      </div>
    </template>

    <!-- Actual restriction is here -->
    <RuleSelectionTree
      :is-total-analysis-entity-max="isTotalAnalysisEntityMax"
      :is-total-crm-entity-max="isTotalCrmEntityMax"
      class="p-2"
      @is-valid="isValid($event, i)"
    />
    
    <!-- This AND/OR bracket only shows when there are NO existing restrictions -->
    <template v-if="clauses[i] && !restrictions.length">
      <div
        class="p-2 and-or-container"
      >
        <ButtonGroup class="and-or-group">
          <CustomButton
            :purpose="clauses[i] === 'and' ? 'active' : 'inactive'"
            small
            @click="setClause('and', i)"
          >
            {{ t('a_n_d') }}
          </CustomButton>
          <CustomButton
            :purpose="clauses[i] === 'or' ? 'active' : 'inactive'"
            small
            @click="setClause('or', i)"
          >
            {{ t('o_r') }}
          </CustomButton>
        </ButtonGroup>
        <CustomButton
          v-if="i > 0"
          icon-color="var(--colour-utility-white)"
          icon-name="trash"
          round
          icon-only
          @click="deleteClause(i)"
        />
      </div>
    </template>
  </template>
  <div class="p-2">
    <CustomButton
      v-if="!maximumEntities"
      icon-color="var(--colour-utility-white)"
      icon-name="plus"
      round
      icon-only
      @click="addClause"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import RuleSelectionTree from './RuleSelectionTree.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { CustomButton } from '@sales-i/dsv3';
import { tAdmin as t } from '@sales-i/utils';
import useAdminUsers from '@/admin/composables/useAdminUsers';

const emit = defineEmits(['isValid']);
const store = useStore();
const {
  isTotalAnalysisEntityMax,
  isTotalCrmEntityMax,
  restrictions,
  totalAnalysisEntities,
  totalCrmEntities,
  rulesetCount,
  rulesets
} = useAdminUsers({ store });

const rulesAreValid = ref([false]);
const clauses = ref(['and']);
const maximumEntities = ref(false);


const isValid = (obj, i) => {
  rulesets.value[i] = {
    ...rulesets.value[i],
    ...obj.values
  };
  rulesAreValid.value[i] = obj.valid;
  let valid = checkValidity();
  emit('isValid', { valid, values: rulesets.value, clauses: clauses.value });
};

const checkValidity = () => {
  let valid = false;
  for(let i = 0, len = rulesAreValid.value.length; i < len; i++) {
    valid = rulesAreValid.value[i];
    if (!valid) break;
  }
  valid = rulesets.value.length === rulesAreValid.value.length ? valid : false;
  return valid;
};

const setClause = (str, i) => {
  clauses.value[i] = str;
};

const addClause = () => {
  rulesetCount.value += 1;
  clauses.value.push('and');
  rulesets.value.push({ mappingId: rulesetCount.value, values: {} });
  let valid = checkValidity();
  emit('isValid', { valid, });
};

const deleteClause = (i) => {
  clauses.value.splice(i, 1);
  if (restrictions.value.length) {
    rulesets.value.splice(i, 1);
    rulesAreValid.value.splice(i, 1);
  } else {
    rulesets.value.splice(i+1, 1);
    rulesAreValid.value.splice(i+1, 1);
  }
  
  let valid = checkValidity();
  emit('isValid', { valid, });
};

onMounted(() => {
  if (!restrictions.value.length) {
    rulesets.value.push({ mappingId: 2, values: {} });
    rulesetCount.value = 2;
  }
});

watch([totalCrmEntities, totalAnalysisEntities], () => {
  if (isTotalCrmEntityMax.value && isTotalAnalysisEntityMax.value) {
    maximumEntities.value = true;
  } else {
    maximumEntities.value = false;
  }
});

</script>

<style lang="scss" scoped>
.rule-tree {
  display: flex;
  > div {
    flex: 1;
  }
}
.branch-heading {
  display: flex;
  align-items: center;
  .branch {
    height: 1px;
    border: 1px solid black;
    flex: 1;
  }
  .hand {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: black;
    margin-right: var(--spacing-1);
  }
}

.and-or-container {
  background-color: var(--colour-panel-action);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.and-or-group {
  display: inline-flex;
  width: auto;
}
</style>

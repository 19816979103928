import { tagConsolidationSection, tagsReportSection } from '@/admin/router/urlBits';
import { GET_MENU_OPTIONS, CLEAR_MENU_OPTIONS } from '@/admin/store/actionType';
import { crm_admin } from '@/shared/store/data/policies';
import { tAdmin as t } from '@sales-i/utils';

// getters
export const getters = {
};

// mutations
export const menuMutations = {
  [GET_MENU_OPTIONS]: (state, data) => {
    state.menu.data = data;
    state.menu.loaded = true;
    state.menu.loading = false;
    state.menu.errors = undefined;
  },
  [CLEAR_MENU_OPTIONS]: (state) => {
    state.menu.data = [];
    state.menu.loaded = false;
    state.menu.loading = true;
    state.menu.errors = undefined;
  },
};

// actions
export const menuActions = {
/* eslint-disable no-unused-vars */
  [GET_MENU_OPTIONS]: async ({ commit, dispatch, rootState }) => {
    const permissions = rootState.pbac.permissionsGranted;
    const p = permissions[crm_admin] || {};
    let data = [
      p.read ? {
        id: 1,
        name: t('tag_reporting'),
        path: tagsReportSection
      } : undefined,
      (p.read && p.update) ? {
        id: 2,
        name: t('tag_consolidation'),
        path: tagConsolidationSection
      } : undefined
    ].filter(x => !!x);
    commit(GET_MENU_OPTIONS, data);
  },
  [CLEAR_MENU_OPTIONS]: ({ commit, }) => {
    commit(CLEAR_MENU_OPTIONS);
  },
};

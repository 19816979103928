<template>
  <CustomForm
    v-if="fieldsToUse.length"
    :formdata="fields"
    :actions="actions"
    :sticky-actions="false"
  />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import {
  phoneRegex,
  nameRegex,
  emailRegex,
} from '@/shared/regex/form';
import CustomForm from '@/shared/components/Form/Form.vue';
import { SET_USER_DETAILS } from '@/shared/store/actionType';
import { GET_REP_CODES } from '@/admin/store/actionType';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const emit = defineEmits(['stageUpdate']);

const store = useStore();
const vroute = useRoute();

const userDetails = computed(() => store.state.admin.users.currentlyEditing.details);
const repCodes = computed(() => store.state.admin.users.rep_codes);
const getRepCodes = () => store.dispatch(`admin/users/${GET_REP_CODES}`);
const setUserDetails = userDetails => store.dispatch(`admin/users/${SET_USER_DETAILS}`, userDetails);

const fields = ref([
  {
    stage: 'User Details',
    fields: [
      {
        label: `${t('first_name')}:`,
        id: 'first_name',
        type: 'text',
        min: 1,
        max: 40,
        placeholder: '',
        required: true,
        disabled: false,
        regex: nameRegex,
        errorMessage: t('please_enter_a_valid_fieldname', {
          fieldname: 'first name'
        }
        ),
      },
      {
        label: `${t('surname')}:`,
        id: 'last_name',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: true,
        disabled: false,
        regex: nameRegex,
        errorMessage: t('please_enter_a_valid_fieldname', {
          fieldname: 'surname'
        }
        ),
      },
      {
        label: `${t('email')}:`,
        id: 'email',
        type: 'email',
        min: 3,
        max: 50,
        placeholder: '',
        required: true,
        disabled: false,
        regex: emailRegex,
        errorMessage: t('please_enter_a_valid_fieldname', {
          fieldname: 'email'
        }
        ),
      },
      {
        label: `${t('telephone')}:`,
        id: 'telephone_1',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_fieldname', {
          fieldname: 'telephone number'
        }
        ),
      },
      {
        label: `${t('alt_telephone')}:`,
        id: 'telephone_2',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_fieldname', {
          fieldname: 'telephone number'
        }
        ),
      },
      {
        label: `${t('associated_sales_rep_codes')}:`,
        id: 'sales_rep_codes',
        type: 'multiselect',
        showSelected: true,
        options: [],
        errorMessage: 'Please allocate to a colleague',
        required: false,
      },
    ],
  }
]);

const fieldsToUse = computed(() => {
  let newFields = [ ...fields.value ];
  if (repCodes?.value?.length) {
    newFields[0].fields[5].options = repCodes.value.map(x => ({ text: x.sales_rep_code, value: x.sales_rep_code, description: x.description }));
  }
  if (vroute.params.addedit === 'edit') {
    if (userDetails.value.first_name) {
      newFields[0].fields.forEach(field => {
        field.value = userDetails.value[field.id] || '';
      });
      // prevents email from being edited if in edit flow
      newFields[0].fields[2].disabled = true;
      const selectedRepCodes = userDetails.value.sales_rep_codes.map(x => ({ text: x.code ?? x.text, value: x.code ?? x.text, description: x.description }));
      newFields[0].fields[5].value = selectedRepCodes;
      newFields[0].fields[5].options = [
        ...selectedRepCodes,
        ...repCodes.value.map(x => ({ text: x.sales_rep_code, value: x.sales_rep_code, description: x.description }))
      ].filter((obj, index, self) => 
        index === self.findIndex((o) => o.value === obj.value)
      );
      return newFields;
    }
    return [];
  }
  return newFields;
});

const actions = ref([
  {
    label: t('cancel'),
    purpose: 'reversed',
    small: true,
    onClick: () => {
      navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}`);
    },
    display: true,
  },
  {
    label: t('continue'),
    small: true,
    onClick: (data) => {
      setUserDetails(data);
      if (vroute.params.id && vroute.params.addedit === 'edit') {
        navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${vroute.params.id}/edit/role`);
      } else {
        navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/add/role`);
      }
    },
    display: true,
    isSubmit: true,
  },
]);

onMounted(() => {
  emit('stageUpdate', 'User details');
  getRepCodes();
});
</script>

<style lang="scss" scoped>
</style>

<template>
  <div
    data-app="true"
    class="application"
  >
    <div
      v-if="!inMaintenance"
      class="application--wrap"
      :class="{ iframe }"
    >
      <AlertQueue
        :items="alerts.data"
        @close-alert="closeAlert"
      />
      <template v-if="isLoggedIn">
        <main :class="`${mq.current} ${platform.data}`">
          <router-view :key="reloadView" />
        </main>
        <MqResponsive target="lg+">
          <TheFooter :navigation="navigation" />
        </MqResponsive>
      </template>
      <TagModal
        v-if="tags.showModal"
        :id="tags.id"
        :entity="tags.entity.name"
      />
      <ConfirmationModal
        v-if="confirmationModal.data.show"
        class="confirmation-modal"
        :heading="confirmationModal.data.heading"
        :message="confirmationModal.data.message"
        :updated-heading="confirmationModal.data.updatedHeading"
        :updated-message="confirmationModal.data.updatedMessage"
        :save-label="confirmationModal.data.saveLabel"
        :cancel-label="confirmationModal.data.cancelLabel"
        :updated-label="confirmationModal.data.updatedLabel"
        :error-save-label="confirmationModal.data.errorSaveLabel"
        :error-cancel-label="confirmationModal.data.errorCancelLabel"
        :icon-name="confirmationModal.data.iconName"
        :icon-color="confirmationModal.data.iconColor"
        :close-on-update="confirmationModal.data.closeOnUpdate"
        :update-function="confirmationModal.data.updateFunction"
        :finish-function="confirmationModal.data.finishFunction"
        :close-function="confirmationModal.data.closeFunction"
        :cancel-function="confirmationModal.data.cancelFunction"
        :error-heading="confirmationModal.data.errorHeading"
        :error-message="confirmationModal.data.errorMessage"
      />
      <ModalQueue
        v-if="isLoggedIn"
        class="modal-queue"
        :items="modalQueue"
      />
      <ReportsModal
        v-if="reportsModal.visible"
        :active-area="reportsModal.activeArea"
      />
      <AddEditWidget
        v-if="openWidgetModal"
        :item="widgetToAdd"
        :dashboards="dashboards"
        @close="updateWidgetToAdd"
        @save="saveToDashboard"
      />
    </div>
    <div
      v-else
      class="application--wrap"
    >
      <Maintenance />
    </div>
    <ModalTeleportTarget />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, onBeforeMount } from 'vue';
import { useMq, MqResponsive } from 'vue3-mq';
import { AlertQueue } from '@sales-i/dsv3';
import ConfirmationModal from '@/shared/components/Modal/ConfirmationModal.vue';
import ModalQueue from '@/shared/components/Modal/ModalQueue.vue';
import ReportsModal from '@/shared/components/ReportsModal/ReportsModal.vue';
import { useStore } from 'vuex';
import {
  CHECK_PERMISSIONS,
  REMOVE_BY_INDEX,
  GET_USER_DETAILS,
  GET_CURRENCY,
  SET_VISIBLE,
  SET_MAINTENANCE_MODE,
  GET_TENANT,
  SET_FEATURE_FLAGS,
  TOGGLE_CUSTOMER_MODE,
} from '@/shared/store/actionType';
import TheFooter from '@/shared/components/TheFooter.vue';
import ModalTeleportTarget from '@/shared/components/ModalTeleportTarget.vue';
import TagModal from '@/shared/components/Tags/TagModal.vue';
import Maintenance from '@/admin/views/Company/Maintenance.vue';

import useNavigation from '@/shared/composables/useNavigation';

import { getPlatform } from '@/shared/utils/platform';
import { useRoute } from 'vue-router';

import router from '@/routers';
import { useHead } from '@unhead/vue';
import useSystem from '@/shared/composables/useSystem';

import {
  userDetailsState,
  currencyState,
  tenantState,
  permissionsState,
  modalQueueState,
  customerModeState,
  updateWidgetToAdd,
  featureFlagsState,
} from '@sales-i/utils';

// import chameleon from '@chamaeleonidae/chmln';

useHead({
  titleTemplate: 'sales-ai - %s',
});
const mq = useMq();
const modalQueue = ref([]);
// const chmlnInitialised = ref(false);
const store = useStore();
const vroute = useRoute();

const { navigation } = useNavigation();
const {
  inMaintenance,
  setIframe,
  setEntity,
  iframe
} = useSystem({ store });

const openWidgetModal = ref(false);
const widgetToAdd = ref({});

const setPermissions = (p) => store.commit(`pbac/${CHECK_PERMISSIONS}`, p);
const setUserDetails = (data) => store.commit(`userDetails/${GET_USER_DETAILS}`, data);
const setCurrency = (currency) => store.commit(`userDetails/${GET_CURRENCY}`, currency);
const setTenantDetails = (tenant) => store.commit(`system/${GET_TENANT}`, tenant);
const removeAlert = index => store.dispatch(`alerts/${REMOVE_BY_INDEX}`, index);
const setMaintenanceMode = bool => store.dispatch(`system/${SET_MAINTENANCE_MODE}`, bool);
const setFeatureFlags = value => store.commit(`system/${SET_FEATURE_FLAGS}`, value);
const setTagsModalVisibility = params => store.dispatch(`tags/${SET_VISIBLE}`, params);
const permissionsGranted = computed(() => store.state.pbac.permissionsGranted);
const platform = computed(() => store.state.platform);
const userDetails = computed(() => store.state.userDetails);
const toggleCustomerMode = bool => store.dispatch(`system/${TOGGLE_CUSTOMER_MODE}`, bool);

const permissionsLoaded = computed(() => Object.keys(permissionsGranted.value).length > 0);
const isLoggedIn = computed(() => userDetails.value.data && userDetails.value.currencyName && permissionsLoaded);
const alerts = computed(() => store.state.alerts);
const tags = computed(() => store.state.tags);
const confirmationModal = computed(() => store.state.confirmationModal);
const reportsModal = computed(() => store.state.reportsModal);
const reloadView = computed(() => store.state.reloadView.reloadViewCounter);

const rxud = userDetailsState.subscribe(({ data }) => {
  setUserDetails(data);
  // if (!chmlnInitialised.value) {
  //   const chmlnId = process.env.VUE_APP_CHMLN_ID;
  //   chameleon.init(
  //     chmlnId,
  //     { 
  //       fastUrl: 'https://fast.chameleon.io/',
  //       forceOverride: true,
  //     }
  //   );
  //   chmlnInitialised.value = true;
  // }
  // chameleon.identify(data.id, {
  //   email: data.email,
  //   name: `${data.first_name} ${data.last_name}`,
  // });
});

const rxff = featureFlagsState.subscribe(({data}) => {
  setFeatureFlags(data);
});

const rxc = currencyState.subscribe(({ name }) => {
  setCurrency(name);
});

const rxt = tenantState.subscribe(tenant => {
  setTenantDetails(tenant);
});

const rxp = permissionsState.subscribe(p => { setPermissions(p); });

const rxmq = modalQueueState.subscribe(newModalQueueState => {
  modalQueue.value = newModalQueueState.data;
});

const rxcm = customerModeState.subscribe(data => { toggleCustomerMode(data); });

watch(vroute, () => {
  setSystemEntity();
});
/* eslint-disable */
onBeforeMount(async () => {
  try {
    await router.isReady();
    checkIfInMaintenance();
    checkIfIframe();
    getPlatform();
    setSystemEntity();
    window.showTagModal = showTagModal;
    // chmlnInitialised.value = true;
    // onSuccess
  } catch (err) {
    // onError
    console.error('admin app before mount', err);
  }
});
onMounted(() => {
  setHeapData();
});
onUnmounted(() => {
  rxud.unsubscribe();
  rxc.unsubscribe();
  rxt.unsubscribe();
  rxp.unsubscribe();
  rxmq.unsubscribe();
  rxcm.unsubscribe();
  rxff.unsubscribe();
});

function checkIfInMaintenance() {
  let { query } = vroute;
  if (query && query.maintenance) {
    setMaintenanceMode(query.maintenance === 'true');
  }
}
function checkIfIframe() {
  const { path } = vroute;
  setIframe(path && path.includes('iframe'));
}
function setHeapData() {
  if (userDetails.value.data && window.heap) {
    const { email } = userDetails.value.data;
    window.heap.addUserProperties({ email });
  } else {
    setTimeout(setHeapData, 100);
  }
}
function closeAlert(index) {
  removeAlert(index);
}
function setSystemEntity() {
  let { path } = vroute;
  let parts = path.split('/');
  if (parts[2]) {
    setEntity(parts[2].toLowerCase());
  }
}
function showTagModal(id, entity) {
  setTagsModalVisibility({
    id,
    entity,
  });
}
async function saveToDashboard(widget) {
  await postWidget(widget);
  updateWidgetToAdd();
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
main {
  display: flex;
  flex-flow: column nowrap;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-12);
  }
}

:deep(.confirmation-modal) {
  .modal.show-modal {
    z-index: $confirmationModalZIndex;
  }
}

:deep(.modal-queue) {
  .modal.show-modal {
    z-index: $modalQueueZIndex;
  }
}
</style>

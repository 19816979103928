<template>
  <h1
    ref="pageTitle"
    class="fw-bld text-3"
  >
    {{ title }}
  </h1>
  <Stage
    :items="stages"
    :selected-index="stage"
    @stage="changeStage"
  />
  <router-view
    @stage-update="stageUpdate"
  />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import Stage from '@/shared/components/Stage/Stage.vue';
import { GET_BY_ID } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, rolesSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const vroute = useRoute();

const store = useStore();

let stagesNames = {
  'Role details': '',
  'Feature restrictions': 'restrictions',
};

const stage = ref(0);
const stageName = ref('Role details');
const stages = ref(['Role details', 'Feature restrictions']);
const changeStage = newStage => {
  let changeStage = stages.value[newStage];
  let newIndex = stages.value.findIndex(s => s === newStage);
  if (!changeStage || newIndex === stage.value) return;
  let {
    params: { id },
  } = vroute;

  if (id) {
    navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/${id}/edit/${stagesNames[changeStage]}`);
  } else if (vroute.params.addedit === 'add') {
    navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/add/${stagesNames[changeStage]}`);
  }
};

const getRole = (params) => store.dispatch(`admin/pbac/${GET_BY_ID}`, params);

const stageUpdate = (newStage) => {
  stageName.value = newStage;
  let newIndex = stages.value.findIndex(s => s === newStage);
  stage.value = newIndex;
};

const title = computed(() => vroute.params.addedit === 'add' ? t('add_a_role') : t('edit_name', { name: 'role' }));

onMounted(() => {
  if (vroute.params.id) {
    getRole({ id: vroute.params.id });
  }
});
</script>

<style lang="scss" scoped></style>

<template>
  <LeftHandNavigation
    v-if="permissions.read"
    :items="translatedMenuItems"
    :active-section="activeSection"
    :loading="menu.loading"
    @item-clicked="itemClicked"
    @active-section-clicked="setActiveSection"
  >
    <router-view />
  </LeftHandNavigation>
</template>

<script setup>
// name: 'TagsSettingsView'
import { tAdmin as t } from '@sales-i/utils';
import { computed, ref, onMounted } from 'vue';
import LeftHandNavigation from '@/admin/components/Layout/LeftHandNavigation.vue';
import { useStore } from 'vuex';
import { GET_MENU_OPTIONS } from '@/admin/store/actionType';
import { baseUrl, companyArea, tagsSection } from '@/admin/router/urlBits';
import usePermissions from '@/shared/composables/usePermissions';
import { crm_admin } from '@/shared/store/data/policies';
import { useRoute, } from 'vue-router';
import { navigateToUrl } from 'single-spa';

const { getPermissions } = usePermissions();
const permissions = getPermissions(crm_admin);

const store = useStore();
const vroute = useRoute();

const activeSection = ref(0);
const menu = computed(() => store.state.admin.tags.menu);
const translatedMenuItems = computed(() =>
  menu.value.data.map(menuItem => ({
    ...menuItem,
    name: t(menuItem.name),
  }))
);

onMounted(() => {
  getMenuOptions();
  setActiveSection();
});

const getMenuOptions = () => store.dispatch(`admin/tags/${GET_MENU_OPTIONS}`);

function itemClicked(item) {
  navigateToUrl(`${baseUrl}/${companyArea}/${tagsSection}/${item.path}`);
}

function setActiveSection(index) {
  if (index === undefined) {
    index = 0;
    let ignore = ['', baseUrl, companyArea, tagsSection];
    let parts = vroute.fullPath.split('/');
    parts.forEach(part => {
      if (!ignore.includes(part)) {
        index = menu.value.data.findIndex(item => item.path === part);
      }
    });
  }
  activeSection.value = index;
}
</script>

<style lang="scss" scoped></style>

<template>
  <CustomCard
    v-if="permissions.read"
    purpose="reversed"
    class="flow tag-report"
    :class="mq.current"
  >
    <template #header>
      <h2
        ref="pageTitle"
        class="fw-sbld"
      >
        {{ t('tag_reporting') }}
      </h2>
    </template>
    <h3 class="fw-sbld">
      {{ t('search_tags') }}
    </h3>
    <TagSearch
      :entities="entities"
      :items="items"
      :loading="loading"
      :selected-entities="selectedEntities"
      :selected-items="selectedItems"
      @items-selected="onItemsSelected"
      @on-check-entity-input="onCheckEntityInput"
    />
    <TagsReportDetailsModal
      v-if="showReportDetails"
      :items="selectedItems"
      @close-modal="showReportDetails = false"
    />
    <template #footer>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          v-if="selectedItems.length"
          purpose="reversed"
          small
          @on-click="onItemsSelected([])"
        >
          {{
            t('clear_selection_count', {
              count: selectedItems.length,
            })
          }}
        </CustomButton>
        <CustomButton
          purpose="action"
          :disabled="!selectedItems.length"
          small
          @on-click="showReportDetails = true"
        >
          {{ t('continue') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomCard>
</template>

<script setup>
// name: 'TagsReport'
import { tAdmin as t } from '@sales-i/utils';
import { useMq } from 'vue3-mq';
import { computed, ref, onMounted } from 'vue';
import TagsReportDetailsModal from '@/admin/components/Company/Tags/TagsReportDetailsModal.vue';
import { CustomCard, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import TagSearch from '@/admin/components/Company/Tags/TagSearch.vue';
import { CLEAR_REPORT } from '@/admin/store/actionType';
import { useStore } from 'vuex';
import usePermissions from '@/shared/composables/usePermissions';
import { crm_admin } from '@/shared/store/data/policies';

const mq = useMq();

const { getPermissions } = usePermissions();
const permissions = getPermissions(crm_admin);

const store = useStore();

const showReportDetails = ref(false);
const selectedEntities = ref([]);
const selectedItems = ref([]);
const entities = [
  { label: t('opportunties'), value: 'opportunities' },
  { label: t('customers'), value: 'customers' },
  { label: t('prospects'), value: 'prospects' },
  { label: t('interactions'), value: 'interactions' },
  { label: t('contacts'), value: 'contacts' },
  { label: t('posts'), value: 'posts' },
  { label: t('files'), value: 'file' },
];

const loading = computed(() => store.state.admin.tags.report.loading);
const reportData = computed(() => store.state.admin.tags.report.data || []);

const items = computed(() => {
  // check if tag entities contains any of selected entities or no entities selected in filter - show all
  if (!selectedEntities.value.length) return reportData.value;
  return reportData.value.filter(tag => {
    if (!tag.entities) return false;
    return Object.keys(tag.entities).some(entity => selectedEntities.value.includes(entity));
  });
});

onMounted(() => {
  clear();
});

const clear = () => store.dispatch(`admin/tags/${CLEAR_REPORT}`);

function onItemsSelected(items) {
  selectedItems.value = items;
}

function onCheckEntityInput($event, entityType) {
  if (!$event) {
    selectedEntities.value = selectedEntities.value.filter(x => x != entityType);
  } else {
    selectedEntities.value.push(entityType);
  }
}
</script>

<style lang="scss" scoped>
.tag-report {
  margin: var(--spacing-3);

  :deep(.c-card--footer) {
    padding: 0;
  }

  .entity-filter {
    display: flex;
    flex-flow: row wrap;
  }

  .btn-group-bg {
    background-color: var(--colour-panel-action);
    padding: var(--spacing-2);

    .button {
      max-width: 200px;
    }
  }

  &.md,
  &.sm,
  &.xs {
    width: initial;
    margin-top: 0;
  }
}
</style>

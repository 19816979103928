<template>
  <CustomModal
    class="add-rules-modal"
    :title="t('add_rule')"
    show-modal
    :max-width="800"
    @close-modal="emit('closeModal')"
  >
    <InlineTabs
      :items="tabs"
      :selected-index="selectedIndex"
      :styles="{
        indent: 'var(--spacing-2)',
        fontSize: 'var(--font-size-4)',
      }"
      @nav-clicked="switchTab"
    />
    <div class="p-2">
      {{ userFriendlyRestrictions }}
    </div>
    <div
      v-if="selectedIndex === 0"
      class="p-2"
    >
      <RuleSelectionTree
        :rule="rule"
        @is-valid="isValid"
      />
    </div>
    <div
      v-if="selectedIndex === 1"
      class="rule-group-container"
    >
      <RuleSelectionGroup
        @is-valid="isValid"
      />
    </div>
    <template #footer>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          v-if="!rule"
          :disabled="formInvalid"
          small
          purpose="action"
          @click="addRule"
        >
          {{ t('add_rule') }}
        </CustomButton>
        <CustomButton
          v-if="rule"
          :disabled="formInvalid"
          small
          purpose="action"
          @click="updateRule"
        >
          {{ t('update_rule') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import { tAdmin as t } from '@sales-i/utils';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import InlineTabs from '@/shared/components/Tabs/InlineTabs.vue';
import RuleSelectionTree from '@/admin/components/Company/Permissions/Users/RuleSelectionTree.vue';
import RuleSelectionGroup from '@/admin/components/Company/Permissions/Users/RuleSelectionGroup.vue';
import { stringRulesFromArray } from '@/admin/store/utils/dataRestrictionFormatting';

const emit = defineEmits(['addRule', 'updateRule', 'closeModal', 'tabSelected']);

const props = defineProps({
  selectedIndex: {
    type: Number,
    default: 0,
  },
  rule: {
    type: [Object],
    default: null
  },
  isMaxCrmRules: {
    type: Boolean,
    default: false,
  },
  isMaxAnalysisRules: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const tabSwitchDisabled = ref(false);
const formInvalid = ref(true);
const rules = ref([]);
const clauses = ref([]);

const restrictions = computed(() => store.state.admin.users.currentlyEditing.restrictions);
const clausesInStore = computed(() => store.state.admin.users.currentlyEditing.clauses);

const userFriendlyRestrictions = computed(() => stringRulesFromArray(restrictions.value, clausesInStore.value));

const tabs = ref([
  {
    title: t('rule'),
    key: 'rule',
  },
  {
    title: t('rule_group'),
    key: 'group',
  },
]);

const addRule = () => {
  emit('addRule', {
    rules: rules.value,
    clauses: clauses.value,
  });
  emit('closeModal');
};

const updateRule = () => {
  emit('updateRule', {
    rules: rules.value,
    clauses: clauses.value,
  });
  emit('closeModal');
};

const isValid = obj => {
  rules.value = obj.values;
  clauses.value = obj.clauses || [];
  formInvalid.value = !obj.valid;
};

const switchTab = tab => {
  if (!tabSwitchDisabled.value) {
    emit('tabSelected', tab.index);
  }
};

onMounted(() => {
  if (restrictions.value.length) {
    emit('tabSelected', 1);
    tabs.value[0].disabled = true;
  }
  if (props.rule) {
    emit('tabSelected', 0);
    tabs.value[0].disabled = false;
    tabs.value[1].disabled = true;
  }
});
</script>

<style lang="scss" scoped>
.add-rules-modal {
  :deep(.content-wrapper) {
    overflow: visible;
  }
}
.btn-group-bg {
  padding-top: 0;
  padding-bottom: 0;

  .button {
    margin-top: 0;
  }
}

.rule-group-container  {
  max-height: 400px;
  overflow: auto;
}
</style>

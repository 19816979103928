import {
  GET_BY_ENTITY,
  GET_BY_ENTITY_LOADING,
  GET_BY_ENTITY_ERROR,
} from '@/shared/store/actionType';
import { tAdmin as t } from '@sales-i/utils';

const customerSettings = [
  {
    setting: t('entity_percent_complete_chip', { entity: t('prospect') }),
    card: {
      title: t('prospect_name'),
      info: '',
      helpText: t('display_the_percentage_completion_of_a_entity_profile', { entity: 'prospect' }),
    }
  }
];

const contactSettings = [
  {
    setting: t('entity_percent_complete_chip', { entity: t('contact') }),
    card: {
      title: 'John Smith',
      info: t('company_name'),
      helpText: t('display_the_percentage_completion_of_a_entity_profile', { entity: t('contact') }),
    }
  },
];

const opportunitySettings = [
  {
    setting: t('entity_percent_complete_chip', { entity: t('opportunity')}),
    card: {
      title: 'Eric Clapton',
      info: t('company_name'),
      helpText: t('display_how_close_to_completion_an_opportunity_is'),
    }
  },
];

// getters
export const getByEntityGetters = {
};

// mutations
export const getByEntityMutations = {
  [GET_BY_ENTITY]: (state, data) => {
    state.data = data;
    state.loaded = true;
    state.loading = false;
  },
  [GET_BY_ENTITY_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [GET_BY_ENTITY_ERROR]: (state, data) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
    state.errors = data;
  },
};

// actions
export const getByEntityActions = {
  [GET_BY_ENTITY]: async ({ commit, }, { entity }) => {
    commit(GET_BY_ENTITY_LOADING);
    try {
      let data = [];
      switch(entity) {
      case 'customers':
        data = customerSettings;
        break;
      case 'contacts':
        data = contactSettings;
        break;
      case 'opportunities':
        data = opportunitySettings;
        break;
      }
      commit(GET_BY_ENTITY, data);
    } catch(e) {
      commit(GET_BY_ENTITY_ERROR, []);
    }

  },
};

export default [{
  stage: 'Add a post',
  fields: [
    {
      label: 'Post title',
      id: 'title',
      type: 'text',
      maxLength: 50,
      placeholder: '',
      value: '',
      required: true,
      disabled: false,
      errorMessage: 'Please enter a Post title',
    },
    {
      label: 'Post',
      id: 'text',
      type: 'mentionable',
      maxLength: 280,
      placeholder: '',
      value: '',
      required: true,
      disabled: false,
      errorMessage: 'Please enter details of your post',
    },
    {
      label: 'add_a_tag',
      id: 'tags',
      type: 'tag',
      placeholder: '',
      tagProps: {
        entity: 'post',
      },
      required: false,
      disabled: false,
    },
  ],
},];

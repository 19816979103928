<template>
  <div class="details-container">
    <slot name="header">
      <h2 class="mb-2">
        {{ name }}
      </h2>
    </slot>
    <div>
      <div
        v-for="(item, index) in value"
        :key="`area-${index}`"
        role="button"
        tabindex="0"
        @drop.prevent="dragDrop($event, index)"
        @dragenter.prevent="dragEnter($event, index)"
        @dragover.prevent
      >
        <SectionsDrop
          v-show="toIndex >= 0 && toIndex === index && index != fromIndex && index != fromIndex + 1"
          class="mb-2"
        >
          {{ t('stage') }} {{ toIndex + 1 }}
        </SectionsDrop>
        <div
          class="drag"
          role="button"
          tabindex="0"
          draggable="true"
          @dragstart="dragStart($event, index)"
        >
          <CustomCard
            :key="`stage-${item.id}`"
            purpose="reversed"
            class="item p-0 my-2"
          >
            <div class="main-area">
              <div class="header">
                <IconBase
                  icon-name="move"
                  icon-color="var(--colour-utility-action)"
                  :height="24"
                  :width="24"
                />
                <h4 class="px-2 stage-title">
                  {{
                    t('stage_number_name', {
                      number: index + 1,
                      name: item.name,
                    })
                  }}
                </h4>
                <CustomButton
                  icon-name="trash"
                  icon-color="var(--colour-utility-action)"
                  icon
                  :height="24"
                  :disabled="!item.deletable"
                  :width="24"
                  @click="del(item, index)"
                />
              </div>
              <div class="form-grid">
                <div class="row">
                  <div class="col col-12 col-md-6">
                    <Field
                      :value="item.name"
                      :label="t('label')"
                      required
                      :error="!item.name ? true : null"
                      :error-message="t('label_is_required')"
                      @input="value => fieldInput(index, 'name', value)"
                    />
                  </div>
                  <div class="col col-12 col-md-6">
                    <Field
                      :value="item.score"
                      type="number"
                      :label="t('percentage_value')"
                      :min="0"
                      :max="100"
                      :error-message="t('stage_score_should_be_between_0_and_100')"
                      @input="value => fieldInput(index, 'score', value)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </CustomCard>
        </div>
      </div>
      <div class="add-item">
        <div class="divider" />
        <CustomButton
          class="add-btn"
          icon-name="plus"
          icon-color="white"
          icon-only
          :icon-height="24"
          :icon-width="24"
          @click="add"
        />
      </div>
    </div>
    <ButtonGroup class="btn-group-bg">
      <CustomButton
        :label="t('update')"
        purpose="reversed"
        @click="save"
      />
    </ButtonGroup>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { CustomCard, IconBase, CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import SectionsDrop from '@/admin/views/CRMSetup/SectionsDrop.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: () => [],
    required: true,
  },
});

const emit = defineEmits(['add', 'move', 'edit', 'delete', 'save']);

const fromIndex = ref(-1);
const toIndex = ref(-1);

const clearDragState = () => {
  fromIndex.value = -1;
  toIndex.value = -1;
};

const dragStart = (ev, index) => {
  clearDragState();
  ev.dataTransfer.effectAllowed = 'move';
  ev.dataTransfer.setData('text/plain', index); 
  fromIndex.value = index;
};

const dragDrop = (ev, toIndex) => {
  const from = parseInt(ev.dataTransfer.getData('text/plain'), 10);
  if (from !== undefined && toIndex !== undefined) {
    emit('move', { from, to: toIndex });
  }
  clearDragState();
};

const dragEnter = (ev, index) => {
  if (fromIndex.value === index) return;
  toIndex.value = index;
};

const add = () => {
  emit('add', props.value?.length || 0);
};

const del = (item, index) => {
  emit('delete', { index, item });
};

const fieldInput = (index, fieldName, value) => {
  emit('edit', { index, fieldName, value });
};

const save = () => {
  emit('save');
};
</script>

<style lang="scss" scoped>
.details-container {
  .drag {
    cursor: grab; /* Ensure drag visibility */
  }

  :deep(.icon-move) {
    cursor: grab;
  }

  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none;

  div.highlighted {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }

  .btn-delete {
    cursor: hand;
  }

  .add-item {
    position: relative;
    height: 2rem;
    padding: var(--spacing-2);

    .divider {
      position: absolute;
      top: 50%;
      left: 1%;
      width: 98%;
      height: 0px;
      border-bottom: 1px solid var(--shadow-colour);
    }

    .add-btn {
      position: absolute;
      top: 0;
      right: var(--spacing-2);
    }
  }

  h2 {
    font-weight: var(--font-weight-semibold);
  }

  .item,
  div.actions {
    padding: 0;

    .details {
      margin-top: 0;
      padding: var(--spacing-3);
      background-color: var(--colour-panel-action);
      text-align: right;

      button {
        margin-left: var(--spacing-1);
        margin-right: var(--spacing-1);
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    padding: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    background-color: var(--colour-panel-alert-base);

    h4.stage-title {
      flex-grow: 1;
      flex: 1;
      max-width: calc(100% - 40px);
      word-wrap: break-word;
      word-break: break-all;
    }

    .stages-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
    .stages-title .stage-title {
      display: flex;
      span {
        font-size: var(--font-size-small);
      }
    }
    .stages-title .percent {
      position: relative;
      top: var(--spacing-1);
    }
  }

  .btn-group-bg {
    margin-top: var(--spacing-3);
    .button {
      margin-top: 0;
    }
  }
}
</style>
<template>
  <div>
    <TagSearch
      :entities="entities"
      :items="items"
      :loading="loading"
      :selected-entities="selectedEntities"
      :selected-items="selectedItems"
      :tag-limit="20"
      @items-selected="onItemsSelected"
      @on-check-entity-input="onCheckEntityInput"
    />

    <ButtonGroup class="btn-group-bg">
      <CustomButton
        v-if="selectedItems.length"
        purpose="reversed"
        small
        @on-click="selectedItems = []"
      >
        {{
          t('clear_selection_count', {
            count: selectedItems.length,
          })
        }}
      </CustomButton>

      <CustomButton
        purpose="action"
        :disabled="!selectedItems.length"
        small
        @on-click="nextStep"
      >
        {{ t('continue') }}
      </CustomButton>
    </ButtonGroup>
  </div>
</template>

<script>
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import TagSearch from '@/admin/components/Company/Tags/TagSearch.vue';

import { mapActions, mapState } from 'vuex';
import { GET_REPORT, CLEAR_REPORT, SET_TAGS_TO_CONSOLIDATE } from '@/admin/store/actionType';
import { baseUrl, companyArea, tagConsolidationSection, tagsSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'TagsFrom',
  components: {
    CustomButton,
    ButtonGroup,
    TagSearch,
  },
  props: {
    entities: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedEntities: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['stageUpdate', 'onCheckEntityInput'],
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    ...mapState({
      loading: state => state.admin.tags.report.loading,
      reportData: state => state.admin.tags.report.data || [],
      consolidateTags: state => state.admin.tags.consolidateTags,
    }),
  },
  mounted() {
    this.$emit('stageUpdate', t('select_tags_to_consolidate'));
  },
  methods: {
    t,
    ...mapActions({
      clear: `admin/tags/${CLEAR_REPORT}`,
      getReportData: `admin/tags/${GET_REPORT}`,
      setTagsToConsolidateTo: `admin/tags/${SET_TAGS_TO_CONSOLIDATE}`,
    }),
    nextStep() {
      const originalTag = this.selectedItems.map(x => x.tag);
      this.setTagsToConsolidateTo({
        original_tag: originalTag,
      });

      navigateToUrl(`${baseUrl}/${companyArea}/${tagsSection}/${tagConsolidationSection}/consolidate-to-tag`);
    },
    onSearchInput(item) {
      this.onSearch((item || {}).tag);
    },
    onItemsSelected(items) {
      this.selectedItems = items;
    },
    onCheckEntityInput($event, entityType) {
      this.$emit('onCheckEntityInput', $event, entityType);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-consolidation {
  margin: var(--spacing-3);

  .entity-filter {
    display: flex;
    flex-flow: row wrap;
  }

  .btn-group-bg {
    background-color: var(--colour-panel-action);
    padding: var(--spacing-2);
    margin: var(--spacing-3) calc(var(--spacing-3) * -1) calc(var(--spacing-3) * -1);

    .button {
      max-width: 200px;
    }
  }
}

.stages {
  max-width: 22rem;
}

.form-group {
  margin-bottom: inherit;
}
</style>

<template>
  <div class="container">
    <Tabs
      :items="navigation"
      :selected-index="selectedIndex"
      @nav-clicked="navClicked"
    />
    <router-view />
  </div>
</template>

<script setup>
// CompanyTabsView
import { tAdmin as t } from '@sales-i/utils';
import { computed, ref, watch, onMounted } from 'vue';
import Tabs from '@/shared/components/Tabs/Tabs.vue';
import { baseUrl, companyArea as area, permissionsSection, tagsSection, targetsSection, logoSection } from '@/admin/router/urlBits';
import { useRoute, } from 'vue-router';
import usePermissions from '@/shared/composables/usePermissions';
import useMailAuth from '@/shared/composables/useMailAuth';
import { tags } from '@/shared/store/data/policies';
import { useHead } from '@unhead/vue';
import { navigateToUrl } from 'single-spa';

const { getPermissions } = usePermissions();
const { isNylasAvailable } = useMailAuth();
const tagsPerm = getPermissions(tags);

const vroute = useRoute();

const selectedIndex = ref(0);

const navigation = computed(() => {
  return [
    tagsPerm.read
      ? {
        title: t('tags'),
        path: `${baseUrl}/${area}/${tagsSection}`,
        key: 'tags',
      }
      : undefined,
    isNylasAvailable.value
      ? {
        title: t('email_sync'),
        path: `${baseUrl}/${area}/email-sync`,
        key: 'email-sync',
      }
      : undefined,
    // {
    //   title: t('languages'),
    //   path: `${baseUrl}/${area}/languages`,
    //   key: 'languages',
    // },
    {
      title: t('storage'),
      path: `${baseUrl}/${area}/storage`,
      key: 'storage',
    },
    {
      title: t('permissions'),
      path: `${baseUrl}/${area}/${permissionsSection}`,
      key: 'permissions',
    },
    {
      title: t('targets'),
      path: `${baseUrl}/${area}/${targetsSection}`,
      key: 'targets',
    },
    {
      title: t('logo'),
      path: `${baseUrl}/${area}/${logoSection}`,
      key: 'logo',
    },
  ].filter(x => !!x);
});

watch(
  () => vroute.path,
  to => setSelectedIndex(to)
);

onMounted(() => {
  setSelectedIndex(vroute.path);
  // set initial tab
  const currentItem = navigation.value[selectedIndex.value];
  useHead({
    title: currentItem.title,
  });
});

function navClicked(data) {
  const { index, item } = data;
  selectedIndex.value = index;
  if (decodeURI(vroute.path) !== item.path) {
    navigateToUrl(item.path);
  }
  useHead({
    title: item.title,
  });
}

function setSelectedIndex(path) {
  selectedIndex.value = Math.max(
    0,
    navigation.value.findIndex(e => {
      const keys = path.split('/');
      return keys.includes(e.key);
    })
  );
}
</script>

<style lang="scss" scoped></style>

<template>
  <CustomModal
    :title="role.data.name || t('role')"
    show-modal
    :max-width="800"
    @close-modal="closeModal"
  >
    <div class="pl-3 pr-3 pb-3">
      <h4 class="mb-1">
        {{ t('users') }}
      </h4>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th
                v-for="item in columns"
                :key="item.key"
                :class="{
                  'sorting-header': currentSort === item.key,
                }"
              >
                {{ t(item.name) }}
                <IconBase
                  v-if="currentSort === item.key"
                  class="sort-icon"
                  :icon-name="currentSortDir === 'desc' ? 'arrow-down' : 'arrow-up'"
                  :height="24"
                  :width="24"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in role.data.members"
              :key="user.id"
            >
              <td>
                <CustomButton
                  class="user-name"
                  purpose="text"
                  @on-click="goToUser(user.id)"
                >
                  {{ user.first_name }}
                  {{ user.last_name }}
                </CustomButton>
              </td>
              <td class="email-cell">
                {{ user.email }}
              </td>
              <td>
                {{ role.data.name }}
              </td>
              <td v-if="permissions.update">
                <div class="actions">
                  <CustomButton
                    purpose="text"
                    @on-click="editUser(user.id)"
                  >
                    {{ t('edit') }}
                  </CustomButton>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template #footer>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          small
          purpose="action"
          @click="closeModal"
        >
          {{ t('o_k') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import { tAdmin as t } from '@sales-i/utils';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { GET_BY_ID } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, usersSection, } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { user_management } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';

const props = defineProps({
  id: {
    type: [String, Number],
    default: 0,
  }
});

const { getPermissions } = usePermissions();

const permissions = computed(() => getPermissions(user_management));

const columns = ref([
  {
    key: 'name',
    name: t('name')
  },
  {
    key: 'email',
    name: t('login')
  },
  {
    key: 'role',
    name: t('role')
  },
  // other columns added in onMounted
]);

const emit = defineEmits(['closeModal']);

const store = useStore();

const role = computed(() => store.state.admin.pbac.selected);
const getRole = params => store.dispatch(`admin/pbac/${GET_BY_ID}`, params);

const goToUser = id => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${id}`);
};

const closeModal = () => {
  emit('closeModal');
};

const editUser = id => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${id}/edit`);
};

onMounted(() => {
  getRole({ id: props.id });

  if (permissions.value.update) {
    columns.value.push({
      key: 'actions',
      name: t('actions')
    });
  }
});
</script>

<style lang="scss" scoped>
.btn-group-bg {
  padding-top: 0;
  padding-bottom: 0;

  .button {
    margin-top: 0;
  }
}

@import '@/shared/assets/scss/_variables';
.table-wrapper {
  background-repeat: no-repeat;
  background-size: 250px 100%, 250px 100%, 16px 100%, 16px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
  overflow: auto;
  transition: all 0.2s;
  position: relative;
}

table {
  position: relative;
  width: 100%;

  th,
  td {
    font-size: inherit;
  }

  thead th {
    min-width: auto;
    background-color: transparent;
    border-bottom: 1px solid black;
  }
}

tr {
  &:nth-child(even) {
    // I have to use a version of the colour-panel-g-2 variable with opacity to allow for the gradient at the end of a scrollable table to show through.
    background: rgba(210, 210, 210, 0.1);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-1);
  border: none;
}

th {
  vertical-align: middle;
  cursor: pointer;

  &.sorting-header {
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }
}

td {
  vertical-align: middle;
  line-height: var(--spacing-2);
}
td.email-cell {
  max-width: 275px;
  overflow-wrap: break-word;
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

.user-name :deep(> div) {
  text-align: left;
}

</style>

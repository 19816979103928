<template>
  <div>
    <TagSearch
      :multi-select="false"
      :entities="entities"
      :items="items"
      :loading="loading"
      :selected-entities="selectedEntities"
      :selected-items="selectedItems"
      @items-selected="onItemsSelected"
      @on-check-entity-input="onCheckEntityInput"
    />
    <ButtonGroup class="btn-group-bg">
      <CustomButton
        v-if="selectedItems.length"
        purpose="reversed"
        small
        @on-click="selectedItems = []"
      >
        {{
          t('clear_selection_count', {
            count: selectedItems.length,
          })
        }}
      </CustomButton>

      <CustomButton
        purpose="action"
        :disabled="!selectedItems.length"
        small
        @on-click="nextStep"
      >
        {{ t('continue') }}
      </CustomButton>
    </ButtonGroup>
    <TagConsolidationConfirmation
      v-if="isModalVisible"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import TagSearch from '@/admin/components/Company/Tags/TagSearch.vue';
import TagConsolidationConfirmation from '@/admin/components/Company/Tags/TagConsolidationConfirmation.vue';

import { mapActions, mapState } from 'vuex';
import { GET_REPORT, CLEAR_REPORT, PUT_TAG_CONSOLIDATION, SET_TAG_TO_CONSOLIDATE_TO } from '@/admin/store/actionType';

export default {
  name: 'TagsTo',
  components: {
    CustomButton,
    ButtonGroup,
    TagConsolidationConfirmation,
    TagSearch,
  },
  props: {
    entities: {
      type: Array,
      default: () => [],
    },
    selectedEntities: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['stageUpdate', 'onCheckEntityInput'],
  data() {
    return {
      // selectedItems: this.singleTagChip,
      selectedItems: [],
      isModalVisible: false,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.admin.tags.report.loading,
      reportData: state => state.admin.tags.report.data || [],
      consolidateTags: state => state.admin.tags.consolidateTags,
      putConsolidatedTags: state => state.admin.tags.putConsolidateTags,
    }),
    items() {
      // check if tag entities contains any of selected entities or no entities selected in filter - show all
      if (!this.selectedEntities.length) return this.reportData;
      return this.reportData.filter(tag => {
        if (!tag.entities) return false;
        return Object.keys(tag.entities).some(entity => this.selectedEntities.includes(entity));
      });
    },
    upToOneTagSelected() {
      return this.selectedItems.length === 1;
    },
    twoOrMoreTagsSelected() {
      return this.selectedItems.length >= 2;
    },
    singleTagChip() {
      // Copy the array with removing the first item
      let newSelectedItems = [...this.selectedItems];

      newSelectedItems.length = 1;

      // Get the first item in the array but if there are 2 or more items in the array, get the second item
      // const tagsSelected = this.twoOrMoreTagsSelected
      //   ? newSelectedItems
      //   : this.selectedItems;

      return newSelectedItems;
    },
  },
  mounted() {
    this.$emit('stageUpdate', 'Select tag to consolidate to');
  },
  methods: {
    t,
    ...mapActions({
      clear: `admin/tags/${CLEAR_REPORT}`,
      getReportData: `admin/tags/${GET_REPORT}`,
      putConsolidateTags: `admin/tags/${PUT_TAG_CONSOLIDATION}`,
      setTagToConsolidateTo: `admin/tags/${SET_TAG_TO_CONSOLIDATE_TO}`,
    }),
    async onSearch(q) {
      await this.clear();
      if (!q) return;
      await this.getReportData({ q });
      return this.items.map(x => ({ ...x, value: x.tag, text: x.tag }));
    },
    nextStep() {
      const toTag = this.singleTagChip[0].tag;
      this.setTagToConsolidateTo({
        to_tag: toTag,
      });
      this.isModalVisible = true;
    },
    onSearchInput(item) {
      this.onSearch((item || {}).tag);
    },
    onItemsSelected(items) {
      this.selectedItems = items;
    },
    onCheckEntityInput($event, entityType) {
      this.$emit('onCheckEntityInput', $event, entityType);
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-consolidation {
  margin: var(--spacing-3);

  .entity-filter {
    display: flex;
    flex-flow: row wrap;
  }

  .btn-group-bg {
    background-color: var(--colour-panel-action);
    padding: var(--spacing-2);
    margin: var(--spacing-3) calc(var(--spacing-3) * -1) calc(var(--spacing-3) * -1);

    .button {
      max-width: 200px;
    }
  }
}

.stages {
  max-width: 22rem;
}

.form-group {
  margin-bottom: inherit;
}
</style>

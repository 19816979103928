<template>
  <div class="calendar-sync-container flex">
    <div class="text-container">
      <h2>{{ t('calendar_events') }}</h2>
      <p>
        {{ t('sync_interactions_from_sales_i_into_your_email_provider_s_calendar_and_vice_versa_connecting_to_an_email_calendar_will_copy_the_events_from_that_calendar_to_sales_i_as_interactions_and_sales_i_interactions_copied_as_calendar_events') }}
      </p>
      <p v-if="isValid">
        {{ t('please_select_the_appropriate_calendar_carefully_as_interactions_calendar_events_created_from_the_two_way_sync_cannot_be_easily_reversed_without_manual_bulk_deletion') }}
      </p>
    </div>
    <div class="buttons-container flex">
      <div class="calendar-choice flex">
        <CustomSelect
          v-if="!calendarName"
          class="calendar-select bg-white text-4"
          :value="selectedCalendarId"
          :disabled="!calendarItems.length"
          :placeholder="t('select_a_calendar_to_enable')"
          :items="calendarItems"
          @on-change="onCalendarChange"
        />
        <span
          v-else
          class="calendar-value text-4"
        >{{ calendarName }}</span>
        <ButtonGroup
          v-if="!calendarName"
          class="buttons-group"
        >
          <CustomButton
            :disabled="!calendarItems.length || !selectedCalendarId"
            small
            purpose="reversed"
            @on-click="clearCalendar"
          >
            {{ t('clear') }}
          </CustomButton>
          <CustomButton
            :disabled="!selectedCalendarId"
            small
            purpose="action"
            @on-click="showConfirmation = true"
          >
            {{ t('confirm') }}
          </CustomButton>
        </ButtonGroup>
        <CustomButton
          v-if="calendarName"
          class="disconnect-button"
          small
          purpose="action"
          @on-click="disconnectCalendar"
        >
          {{ t('stop_calendar_sync') }}
        </CustomButton>
      </div>
      <div
        v-if="selectedCalendarId && showConfirmation"
        class="confirmation-container flex"
      >
        <div class="flex">
          <IconBase
            icon-name="warning"
            icon-color="var(--colour-brand-mandy)"
            width="48"
            height="48"
          />
          <p class="text-4">
            {{ t('are_you_sure_you_want_to_initiate_two_way_sync_of_all_interactions_events_in_this_calendar') }}
          </p>
        </div>
        <ButtonGroup
          v-if="showConfirmation"
          class="buttons-group"
        >
          <CustomButton
            icon-name="close"
            icon-color="var(--colour-brand-mandy)"
            small
            purpose="reversed"
            @on-click="clearCalendar"
          >
            {{ t('cancel') }}
          </CustomButton>
          <CustomButton
            icon-name="check"
            icon-color="var(--colour-data-de-york)"
            small
            purpose="reversed"
            @on-click="setCalendar"
          >
            {{ t('yes_i_m_sure') }}
          </CustomButton>
        </ButtonGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import { CustomSelect, CustomButton, IconBase } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import useMailAdmin from '@/admin/composables/useMailAdmin';

const store = useStore();

defineProps({
  isValid: {
    type: Boolean,
    default: undefined,
  },
  calendarName: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['calendar-updated']);

const { getCalendar, putPrimaryCalendar, deleteCalendar } = useMailAdmin({ store });

const selectedCalendarId = ref(null);
const showConfirmation = ref(false);

const calendarItems = computed(() => {
  const calendars = store.state.admin.email.calendar.data;
  return calendars.map(calendar => ({
    value: calendar.id,
    text: calendar.name,
  }));
});

onMounted(async () => {
  await getCalendar();
});

const setCalendar = async () => {
  if (selectedCalendarId.value) {
    try {
      await putPrimaryCalendar(selectedCalendarId.value);
      emit('calendar-updated');
      showConfirmation.value = false;
    } catch (error) {
      console.error('Failed to set calendar', error);
    }
  }
};

const clearCalendar = () => {
  showConfirmation.value = false;
  selectedCalendarId.value = null;
};

const onCalendarChange = (newId) => {
  selectedCalendarId.value = newId;
};

const disconnectCalendar = async () => {
  try {
    await deleteCalendar();
    selectedCalendarId.value = null;
    emit('calendar-updated'); 
  } catch (error) {
    console.error('Failed to disconnect calendar', error);
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

div.flex {
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
  }
}

.text-container {
  width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 50%;
  }

  h2, p {
    margin-bottom: var(--spacing-2);
    color: var(--colour-utility-black);
  }

  p {
    font-size: var(--font-size-4);
    margin-bottom: var(--spacing-2);
  }
}

.buttons-container {
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  gap: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 40%;
  }

  .calendar-value {
    background-color: var(--colour-panel-g-16);
    width: 100%;
    text-align: center;
    padding: var(--spacing-1) 0;
    border-radius: var(--border-radius-half);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: initial;
      min-width: 50%;
    }
  }

  .confirmation-container {
    background-color: var(--colour-data-mandy-light);
    width: 100%;
    padding: var(--spacing-3);
    flex-direction: column;
    border-bottom-left-radius: var(--border-radius-1);
    border-bottom-right-radius: var(--border-radius-1);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      gap: 0;
    }

    .buttons-group {
      width: auto;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        align-self: flex-end;
      }
    }
  }
}

.calendar-choice {
  width: 100%;
  padding: var(--spacing-3);
  background-color: var(--colour-panel-action);
  border-top-left-radius: var(--border-radius-1);
  border-top-right-radius: var(--border-radius-1);
  justify-content: space-evenly;

  .buttons-group {
    width: auto;
    flex-wrap: nowrap;
    margin: 0 auto;
  }

  .calendar-select {
    margin-bottom: 0;
    width: 100%;
  }

  .disconnect-button {
    background-color: var(--colour-brand-mandy);
    width: 100%;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: initial;
    }
  }
}
</style>

// import Vue from 'vue';
import { createStore } from 'vuex';
import alerts from '@/shared/store/modules/alerts';
import comments from '@/shared/store/modules/comments';
import customSections from '@/shared/store/modules/customSections';
import confirmationModal from '@/shared/store/modules/confirmationModal';
import dashboards from '@/shared/store/modules/dashboards';
import journey from '@/shared/store/modules/journey';
import mentions from '@/shared/store/modules/mentions';
import modalQueue from '@/shared/store/modules/modalQueue';
import notifications from '@/shared/store/modules/notifications';
import pbac from '@/shared/store/modules/pbac';
import platform from '@/shared/store/modules/platform';
import posts from '@/shared/store/modules/posts';
import reloadView from '@/shared/store/modules/reloadView';
import reportsModal from '@/shared/store/modules/reportsModal';
import searchBarResults from '@/shared/store/modules/searchBarResults';
import system from '@/shared/store/modules/system';
import tags from '@/shared/store/modules/tags';
import users from '@/shared/store/modules/users';
import userDetails from '@/shared/store/modules/userDetails';

import adminStore from '@/admin/store';
import crmStore from '@/crm/store';
import intelligenceStore from '@/intelligence/store';


// const debug = process.env.NODE_ENV !== 'production';

// Vue.config.devtools = debug;

// Plugins Examples
const subscribe = store => {
  // eslint-disable-next-line no-unused-vars
  store.subscribe((mutation, state) => {});
};

const store = createStore({
  modules: {
    alerts,
    comments,
    customSections,
    confirmationModal,
    dashboards,
    journey,
    mentions,
    modalQueue,
    notifications,
    pbac,
    platform,
    posts,
    reloadView,
    reportsModal,
    searchBarResults,
    system,
    tags,
    users,
    userDetails,
    // specialised stores
    admin: adminStore,
    crm: crmStore,
    intelligence: intelligenceStore,
  },
  strict: true,//debug,
  plugins: [subscribe]
});

export default store;

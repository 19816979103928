import { absDateRegex, relativeDateRegex } from '@/shared/regex/datepicker';
import { tAdmin as t, dates } from '@sales-i/utils';
import { DateTime } from 'luxon';

// date values
export const YEARLY_COMPARE_DATE_RANGE = 'yearly';
export const MONTHLY_COMPARE_DATE_RANGE = 'monthly';

// date titles
export const YTD_TITLE = t('ytd');
export const LAST_MONTH_TITLE = t('last_month');
export const THREE_MONTHS_TITLE = t('last_3_months');
export const SINCE_TITLE = t('since');
export const DATE_RANGE_TITLE = t('set_date_range');

export const YEARLY_COMPARE_DATE_RANGE_TITLE = t('yearly_comparison');
export const MONTHLY_COMPARE_DATE_RANGE_TITLE = t('monthly_comparison');

export const DATE_FILTER_KEYS = ['date_from', 'date_to', 'date_from2', 'date_to2'];

// months
export const JANUARY = 'JAN';
export const FEBRUARY = 'FEB';
export const MARCH = 'MAR';
export const APRIL = 'APR';
export const MAY = 'MAY';
export const JUNE = 'JUN';
export const JULY = 'JUL';
export const AUGUST = 'AUG';
export const SEPTEMBER = 'SEP';
export const OCTOBER = 'OCT';
export const NOVEMBER = 'NOV';
export const DECEMBER = 'DEC';

export const JANUARY_TITLE = t('january');
export const FEBRUARY_TITLE = t('february');
export const MARCH_TITLE = t('march');
export const APRIL_TITLE = t('april');
export const MAY_TITLE = t('may');
export const JUNE_TITLE = t('june');
export const JULY_TITLE = t('july');
export const AUGUST_TITLE = t('august');
export const SEPTEMBER_TITLE = t('september');
export const OCTOBER_TITLE = t('october');
export const NOVEMBER_TITLE = t('november');
export const DECEMBER_TITLE = t('december');

export const CUSTOM_ABS_DATES_OPTION_VALUE = 'custom-datepicker';
export const CUSTOM_RELATIVE_OPTION_VALUE = 'custom-relative';
export const DEFAULT_DATE_FILTER_OPTION = {
  text: t('ytd'),
  value: 'today/y',
  date_from: 'today/y',
  date_to: 'today',
};

export const CUSTOM_ABS_DATE_FILTER_OPTION = {
  text: t('custom_datepicker'),
  value: CUSTOM_ABS_DATES_OPTION_VALUE,
  date_from: '',
  date_to: '',
};

export const CUSTOM_RELATIVE_DATE_FILTER_OPTION = {
  text: t('custom_relative'),
  value: CUSTOM_RELATIVE_OPTION_VALUE,
  date_from: '',
  date_to: '',
};

export const DATE_FILTER_OPTIONS = [
  DEFAULT_DATE_FILTER_OPTION,
  {
    text: t('month_to_date'),
    value: 'today/m',
    date_from: 'today/m',
    date_to: 'today',
  },
  {
    text: t('last_month'),
    value: 'today-1m',
    date_from: 'today-1m',
    date_to: 'today',
  },
  {
    text: t('last_3_months'),
    value: 'today-3m',
    date_from: 'today-3m',
    date_to: 'today',
  },
  {
    text: t('last_6_months'),
    value: 'today-6m',
    date_from: 'today-6m',
    date_to: 'today',
  },
  {
    text: t('last_week'),
    value: 'today-1w',
    date_from: 'today-1w',
    date_to: 'today',
  },
  {
    text: t('yesterday'),
    value: 'today-1d',
    date_from: 'today-1d',
    date_to: 'today',
  },
  CUSTOM_ABS_DATE_FILTER_OPTION,
  CUSTOM_RELATIVE_DATE_FILTER_OPTION
];

export const checkDateRegex = (type, value) => type === 'date' 
  ? absDateRegex.test(value || '') 
  : relativeDateRegex.test(value || '');
  
export const whichDateOption = ({ date_from, date_to}) => 
  (checkDateRegex('date', date_from) || checkDateRegex('date', date_to)) 
    ? CUSTOM_ABS_DATE_FILTER_OPTION
    : DATE_FILTER_OPTIONS.find(o => o.value != CUSTOM_ABS_DATES_OPTION_VALUE && 
      o.date_from == date_from && o.date_to == date_to) || CUSTOM_RELATIVE_DATE_FILTER_OPTION;

export const DATE_FILTER_OPTIONS_V2 = [
  {
    text: t('custom_date_range'),
    value: 'custom',
    date_from: '',
    date_to: '',
  },
  {
    text: t('year_to_date'),
    value: 'today/y',
    date_from: 'today/y',
    date_to: 'today',
    allowCompletePeriods: true
  },
  {
    text: t('month_to_date'),
    value: 'today/m',
    date_from: 'today/m',
    date_to: 'today',
  },
  {
    text: t('last_12_months'),
    value: 'today-1y',
    date_from: 'today-1y',
    date_to: 'today',
    allowCompletePeriods: true,
    isMonthly: true
  },
  {
    text: t('last_6_months'),
    value: 'today-6m',
    date_from: 'today-6m',
    date_to: 'today',
    allowCompletePeriods: true,
    isMonthly: true
  },
  {
    text: t('last_3_months'),
    value: 'today-3m',
    date_from: 'today-3m',
    date_to: 'today',
    allowCompletePeriods: true,
    isMonthly: true
  },
  {
    text: t('last_month'),
    value: 'today-1m',
    date_from: 'today-1m',
    date_to: 'today',
    allowCompletePeriods: true,
    isMonthly: true
  },
  {
    text: t('last_week'),
    value: 'today-1w',
    date_from: 'today-1w',
    date_to: 'today',
  },
  {
    text: t('yesterday'),
    value: 'today-1d',
    date_from: 'today-1d',
    date_to: 'today',
  },
];

export function getDateTimeOptions({ isCompletePeriods = false, isMonthly = false } = {}) {
  const currMonth = DateTime.local().month;
  return DATE_FILTER_OPTIONS_V2
    .filter(x => 
      (!isCompletePeriods || x.allowCompletePeriods) 
      && (!isMonthly || x.isMonthly) 
      && (x.value != 'today/y' || currMonth != 1)
    );
}

/**
 * TODO: check what happens if period is 4 weeks or 30 days
 * Returns complete periods range for given option { value }
 * @param {*} optionValue - 'today/y', 'today-1y', ...
 * @returns 
 */
export function getCompletePeriods(optionValue) {
  const currentDate = DateTime.local();
  const startOfThisMonth = currentDate.startOf('month');
  let date_to = startOfThisMonth.minus({ seconds: 1 });
  let date_from = date_to;

  switch (optionValue) {
  case 'today/y':  
    date_from = currentDate.startOf('year');
    break;
  case 'today-1y':  
    date_from = startOfThisMonth.minus({ months: 12 });
    break;
  case 'today-6m':  
    date_from = startOfThisMonth.minus({ months: 6 });
    break;
  case 'today-3m':  
    date_from = startOfThisMonth.minus({ months: 3 });
    break;
  case 'today-1m':  
    date_from = startOfThisMonth.minus({ months: 1 });
    break;
  default:
    break;
  }

  const date_from2 = dates.forReportApi(date_from.minus({ year: 1 }));
  const date_to2 = dates.forReportApi(date_to.minus({ year: 1 }));
  date_from = dates.forReportApi(date_from);
  date_to = dates.forReportApi(date_to);

  return {
    date_from,
    date_to,
    date_from2,
    date_to2
  };
}

export const PREVIOUS_DATE_FILTER_OPTIONS_V2 = [
  {
    text: t('custom_date_range'),
    value: 'custom',
    date_from: '',
    date_to: '',
  },
  {
    text: t('previous_year_to_date'),
    value: 'today/y-1y',
    date_from: 'today/y-1y',
    date_to: 'today-1y',
  },
  {
    text: t('previous_month_to_date'),
    value: 'today/m-1m',
    date_from: 'today/m-1m',
    date_to: 'today-1m',
  },
  {
    text: t('previous_12_months'),
    value: 'today-1y-1y',
    date_from: 'today-1y-1y',
    date_to: 'today-1y',
  },
  {
    text: t('previous_6_months'),
    value: 'today-6m-6m',
    date_from: 'today-6m-6m',
    date_to: 'today-6m',
  },
  {
    text: t('previous_3_months'),
    value: 'today-3m-3m',
    date_from: 'today-3m-3m',
    date_to: 'today-3m',
  },
  {
    text: t('previous_month'),
    value: 'today-1m-1m',
    date_from: 'today-1m-1m',
    date_to: 'today-1m',
  },
  {
    text: t('previous_week'),
    value: 'today-1w-1w',
    date_from: 'today-1w-1w',
    date_to: 'today-1w',
  },
  {
    text: t('previous_day'),
    value: 'today-1d-1d',
    date_from: 'today-1d-1d',
    date_to: 'today-1d',
  },
];

// date range options filter
export const DATE_RANGE_OPTIONS_SET = [
  {
    text: YEARLY_COMPARE_DATE_RANGE_TITLE,
    value: YEARLY_COMPARE_DATE_RANGE
  },
  {
    text: MONTHLY_COMPARE_DATE_RANGE_TITLE,
    value: MONTHLY_COMPARE_DATE_RANGE
  }
];

// date range set for yearly filter
const getYearToDate = (subtractedYears = 0) => new Date().getFullYear() - subtractedYears;
export const DATE_RANGE_YEARLY_OPTIONS = [
  {
    text: getYearToDate(),
    value: getYearToDate()
  },
  {
    text: getYearToDate(1),
    value: getYearToDate(1)
  },
  {
    text: getYearToDate(2),
    value: getYearToDate(2)
  },
  {
    text: getYearToDate(3),
    value: getYearToDate(3)
  },
  {
    text: getYearToDate(4),
    value: getYearToDate(4)
  },
  {
    text: getYearToDate(5),
    value: getYearToDate(5)
  }
];

// date range set for monthly filter
export const DATE_RANGE_MONTHLY_OPTIONS = [
  {
    text: JANUARY_TITLE,
    value: JANUARY
  },
  {
    text: FEBRUARY_TITLE,
    value: FEBRUARY
  },
  {
    text: MARCH_TITLE,
    value: MARCH
  },
  {
    text: APRIL_TITLE,
    value: APRIL
  },
  {
    text: MAY_TITLE,
    value: MAY
  },
  {
    text: JUNE_TITLE,
    value: JUNE
  },
  {
    text: JULY_TITLE,
    value: JULY
  },
  {
    text: AUGUST_TITLE,
    value: AUGUST
  },
  {
    text: SEPTEMBER_TITLE,
    value: SEPTEMBER
  },
  {
    text: OCTOBER_TITLE,
    value: OCTOBER
  },
  {
    text: NOVEMBER_TITLE,
    value: NOVEMBER
  },
  {
    text: DECEMBER_TITLE,
    value: DECEMBER
  }
];

// Targets intervals
export const TARGET_INTERVALS = [
  {
    text: t('yearly'),
    value: 'YEARLY',
    periods: 1,
  },
];

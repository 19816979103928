<template>
  <div class="bubbles-section">
    <template
      v-for="(bubble, index) in bubbles"
      :key="bubble.id"
    >
      <h3 v-if="index">
        AND
      </h3>
      <Bubble
        :bubble-data="bubble"
        class="target-bubble"
      />
    </template>
  </div>
  <div class="values-section">
    <div class="values">
      <Field
        v-for="field in fields"
        :key="field.id"
        class="single-value"
        v-bind="field"
        @valid="validateField"
      />
    </div>
  </div>
  <div class="form-actions">
    <CustomButton
      purpose="action"
      small
      :disabled="!valid"
      @on-click="completeStage"
    >
      {{ t('add_values') }}
    </CustomButton>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import Bubble from '@/intelligence/components/Bubble/Bubble';
import Field from '@/shared/components/Form/Field.vue';
import { TARGET_INTERVALS } from '@/intelligence/store/data/dateFilters';
import useEnquiries from '@/intelligence/composables/useEnquiries';

const emit = defineEmits(['completeStage']);

const props = defineProps({
  targetData: {
    type: Object,
    default: () => ({})
  },
});

// data
const fields = ref([]);
const { bubbleData, fetchBubbleData } = useEnquiries();

// computed
const bubbles = computed(() =>
  props.targetData.dimensions.length && bubbleData.value
    ? props.targetData.dimensions.map(dim => {
      const { id, title, area } = bubbleData.value.find(bubble => bubble.id == dim.id);
      return {
        id,
        title,
        area,
        disabled: false,
        clickable: false,
        description: false,
        xButtonEvent: false,
        checked: false,
      };
    })
    : []
);
const periodsNumber = computed(() => {
  const interval = TARGET_INTERVALS.find(interval => interval.value === props.targetData.interval);
  return interval ? interval.periods : 0;
});
const valid = computed(() => fields.value.every(field => field.valid));

watch(periodsNumber, () => {
  const predefinedValues = props.targetData.values;
  const hasIntervalChanged = predefinedValues.length !== periodsNumber.value;
  fields.value = [];
  for (let i = 0; i < periodsNumber.value; i++) {
    fields.value.push({
      label: `P${i+1} ${t('value')}`,
      id: `p${i+1}`,
      type: 'text',
      value: predefinedValues.length && !hasIntervalChanged ? predefinedValues[i] : '',
      errorMessage: t('please_enter_target_value'),
      valid: predefinedValues.length && !hasIntervalChanged,
      regex: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
      required: true,
    });
  }
},
{ immediate: true });

// lifecycle hooks
onMounted(async () => {
  const inputElement = document.getElementById('p1');
  if (!bubbleData.value) await fetchBubbleData();
  if(inputElement) inputElement?.focus();
});

// methods
function validateField(item) {
  fields.value.forEach(field => {
    if (field.id === item.id) {
      field.value = item.value;
      field.valid = item.isValid;
    }
  });
}
function completeStage() {
  const targetData = { values: fields.value.map(field => field.value) };
  emit('completeStage', { targetData, newStage: 4 });
}
</script>

<style lang="scss" scoped>
.bubbles-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: var(--spacing-2) var(--spacing-3);

  h3 {
    font-weight: var(--font-weight-regular);
    margin: 0 var(--spacing-4);
  }

  .target-bubble {
    width: fit-content;
  }
}

.values-section {
  margin: var(--spacing-4);
  flex-grow: 1;

  .values {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .single-value {
      width: 200px;
      height: 80px;
    }
  }
}

.form-actions {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: var(--spacing-4);
  padding: var(--spacing-2) 0 var(--spacing-2);
  background-color: var(--colour-panel-action);
}
</style>

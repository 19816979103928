<template>
  <CustomCard
    v-if="permissions.read && permissions.update"
    purpose="reversed"
    :title="t('consolidate_tags')"
    class="flow tag-consolidation"
    :class="mq.current"
  >
    <div>
      <Stage
        :items="stages"
        :selected-index="stage"
        @stage="changeStage"
      />
    </div>

    <h3 class="fw-sbld">
      {{ t('search_tags') }}
    </h3>

    <router-view
      :entities="entities"
      :items="items"
      :loading="loading"
      :selected-entities="selectedEntities"
      :selected-items="selectedItems"
      @on-check-entity-input="onCheckEntityInput"
      @stage-update="stageUpdate"
    />
  </CustomCard>
</template>

<script setup>
// TagConsolidation
import { tAdmin as t } from '@sales-i/utils';
import { useMq } from 'vue3-mq';
import { computed, ref } from 'vue';
import { CustomCard } from '@sales-i/dsv3';
import Stage from '@/shared/components/Stage/Stage.vue';
import { useStore } from 'vuex';
import { CLEAR_REPORT } from '@/admin/store/actionType';
import usePermissions from '@/shared/composables/usePermissions';
import { crm_admin } from '@/shared/store/data/policies';
import { baseUrl, companyArea, tagConsolidationSection, tagsSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const { getPermissions } = usePermissions();
const permissions = getPermissions(crm_admin);

const mq = useMq();
const store = useStore();

const selectedEntities = ref([]);
const selectedItems = ref([]);
const entities = [
  { label: t('opportunties'), value: 'opportunities' },
  { label: t('customers'), value: 'customers' },
  { label: t('prospects'), value: 'prospects' },
  { label: t('interactions'), value: 'interactions' },
  { label: t('contacts'), value: 'contacts' },
  { label: t('posts'), value: 'posts' },
];
const stage = ref(0);

const stagesNames = {
  [t('select_tags_to_consolidate')]: tagConsolidationSection,
  [t('select_tag_to_consolidate_to')]: `${tagConsolidationSection}/consolidate-to-tag`,
};
const loading = computed(() => store.state.admin.tags.report.loading);
const reportData = computed(() => store.state.admin.tags.report.data || []);

const items = computed(() => {
  // check if tag entities contains any of selected entities or no entities selected in filter - show all
  if (!selectedEntities.value.length) return reportData.value;
  return reportData.value.filter(tag => {
    if (!tag.entities) return false;
    return Object.keys(tag.entities).some(entity => selectedEntities.value.includes(entity));
  });
});

const stages = computed(() => [t('select_tags_to_consolidate'), t('select_tag_to_consolidate_to')]);

const clear = () => store.dispatch(`admin/tags/${CLEAR_REPORT}`);

function changeStage(newStage) {
  let changeStage = stages.value[newStage];
  let newIndex = stages.value.findIndex(stage => stage === newStage);
  if (newIndex === stage.value) return;
  stage.value = newIndex;
  navigateToUrl(`${baseUrl}/${companyArea}/${tagsSection}/${stagesNames[changeStage]}`);
}

function stageUpdate(newStage) {
  let newIndex = stages.value.findIndex(stage => stage === newStage);
  stage.value = newIndex;
  clear();
}

function onCheckEntityInput($event, entityType) {
  if (!$event) {
    selectedEntities.value = selectedEntities.value.filter(x => x != entityType);
  } else {
    selectedEntities.value.push(entityType);
  }
}
</script>

<style lang="scss" scoped>
.tag-consolidation {
  margin: var(--spacing-3);

  :deep(.button-group) {
    width: initial;
  }

  .entity-filter {
    display: flex;
    flex-flow: row wrap;
  }

  .btn-group-bg {
    background-color: var(--colour-panel-action);
    padding: var(--spacing-2);

    .button {
      max-width: 200px;
    }
  }

  &.md,
  &.sm,
  &.xs {
    width: initial;
    margin-top: 0;
  }
}
.stages {
  max-width: 22rem;
}
.form-group {
  margin-bottom: inherit;
}
</style>

<template>
  <div>
    <div
      v-if="drill"
      class="description"
    >
      <CustomButton
        class="close-button"
        purpose="neutral"
        icon-name="close-line"
        icon-color="var(--colour-utility-action)"
        round
        :icon-width="32"
        :icon-height="32"
        @click="disableSelectBubblesPopup"
      />
    </div>
    <div class="bubbles-container">
      <BubbleSearch
        class="search"
        :bubble-areas="bubbleAreas"
        @search-bubbles="updateSearchBubbleQuery"
        @area-selected="selectedArea = $event"
      />
      <div class="bubbles">
        <BufferImage
          v-if="bubblesLoading && !showBubbles"
          color="black"
          float="center"
          class="loading-spinner"
        />
        <div
          v-for="(area, index) in bubbleAreas"
          v-show="filterBubbles(area).length > 0"
          :key="index"
          class="section"
        >
          <div class="bubble-title">
            <div
              class="icon"
              :style="{ background: getIconBackground(area) }"
            >
              <IconBase
                :width="40"
                :height="40"
                :icon-name="getIcon(area)"
                icon-color="var(--colour-utility-white)"
              />
            </div>
            <h3 :class="{ 'uppercase': area === 'Crm' }">
              {{ t(area.toLowerCase()) }}
            </h3>
          </div>
          <div class="list">
            <div
              v-for="bubble in getFilteredBubbles(area)"
              v-show="showBubble(bubble)"
              :key="bubble.id"
              class="option"
              :class="{ active: isBubbleActive(bubble.id) }"
            >
              <Bubble
                :key="bubbleRefreshKey"
                :bubble-data="getBubbleData(bubble)"
                @bubble-click="setBubbles"
                @close-button-click="setBubbles"
              />
            </div>
            <p
              v-if="!getFilteredBubbles(area).length > 0"
              class="no-dimensions"
            >
              {{ t('no_more_dimensions_left_in_this_category') }}
            </p>
          </div>
          <CustomButton
            v-if="!isMobile && isExpandable(area)"
            :label="showAllBubbles ? t('view_less') : t('view_more')"
            class="view-more"
            purpose="text"
            @click="showAllBubbles = !showAllBubbles"
          />
        </div>
        <div
          v-if="showSubmit"
          class="submit"
        >
          <CustomButton
            action="submit"
            :label="buttonLabel"
            @click="goToReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// TODO: this is too complicated imo, why do we provide intialBubbles in different format to active bubbles?
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useMq } from 'vue3-mq';
import { BufferImage, CustomButton, IconBase } from '@sales-i/dsv3';
import Bubble from '@/intelligence/components/Bubble/Bubble';
import { alertsScope, baseUrl, enquiryScope, productSearchScope, reportScope } from '@/intelligence/router/urlBits';
import BubbleSearch from '@/intelligence/components/Bubble/BubbleSearch';
import breakpoints from '@/shared/utils/breakpoints';
import { ENQUIRY_DIMENSION_1, ENQUIRY_DIMENSION_2 } from '@/intelligence/store/data/kpis';
import { tAdmin as t, ReportTypes } from '@sales-i/utils';
import { BUBBLE_AREA_CUSTOMER, BUBBLE_AREA_PRODUCT, BUBBLE_AREA_SALES, BUBBLE_AREA_CALENDAR } from '@/intelligence/store/data/bubbles';
import { navigateToUrl } from 'single-spa';
import useShared from '@/intelligence/composables/useShared';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useSystem from '@/shared/composables/useSystem';

const props = defineProps({
  drill: { type: Boolean, default: false },
  hiddenBubbles: { type: Array, default: () => [] },
  hiddenBubbleAreas: { type: Array, default: () => [] },
  dateFilter: { type: Object, default: () => ({}) },
  buttonLabels: { type: Array, default: () => [] },
  reportType: { type: String, default: '' },
  initialActiveBubbles: { type: Array, default: () => [] },
  disableSubmit: { type: Boolean, default: false },
});

const emit = defineEmits(['drillSelected', 'changeStep', 'goToReport', 'bubbleClick']);

const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const mq = useMq();

const { requestParameters, getInputData, disableSelectBubblesPopup } = useShared({ store });
const { bubbleData, bubbleAreas, bubblesLoading, 
  period: periodFilter, dimensionsNumber, fetchBubbleData } = useEnquiries({ store });
const { iframe } = useSystem({ store });

// State
const activeBubbles = ref([]);
const activeAreas = ref([]);
const bubbleRefreshKey = ref(0);
const searchQuery = ref('');
const selectedArea = ref('All');
const showAllBubbles = ref(false);

const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));

// Computed properties
const inputData = computed(() => getInputData(props.reportType));
const isProductSearch = computed(() => vroute.params?.communityCode === productSearchScope);
const isSalesVsGaps = computed(() => props.reportType === ReportTypes.SALES_VS_GAPS);
const isMatrix = computed(() => props.reportType === ReportTypes.MATRIX);
const isFullPicture = computed(() => props.reportType === ReportTypes.FULL_PICTURE);
const isVariance = computed(() => props.reportType === ReportTypes.VARIANCE);

const enquiryParams = computed(() => {
  let urlParams = { ...props.dateFilter };
  const prefix = props.reportType !== ReportTypes.MATRIX && !isSalesVsGaps.value ? '1-' : '';
  activeBubbles.value.forEach(activeBubble => {
    urlParams[`${prefix}${Object.keys(activeBubble)[0]}`] = Object.values(activeBubble)[0];
  });
  if (isSalesVsGaps.value) {
    urlParams['xaxis'] = periodFilter.value;
  }
  if (isSalesVsGaps.value || isMatrix.value) urlParams['measure'] = requestParameters.value.measure;
  if (isProductSearch.value) {
    urlParams = {
      ...urlParams,
      ...vroute.query,
    };
  }
  if (isSalesVsGaps.value) {
    urlParams['activeDim'] = 'dim1';
  }
  return urlParams;
});

const bubbleAxis = computed(() => {
  if (!activeBubbles.value.length) return dimension1.value.value;
  return Object.keys(activeBubbles.value[0]).includes(dimension1.value.value)
    ? dimension2.value.value
    : dimension1.value.value;
});

const getAxisParams = computed(() => {
  if (!Object.keys(vroute.query).length || iframe.value) return false;
  const dimension = dimension1.value || dimension2.value;
  return vroute.query[dimension.value];
});

const showBubbles = computed(() => !!bubbleData.value);

const showSubmit = computed(() => {
  return (
    !props.disableSubmit &&
    (isSalesVsGaps.value && activeBubbles.value.length >= 1 ||
    (activeBubbles.value.length >= dimensionsNumber.value &&
    activeBubbles.value.some((e) => !!e[dimension1.value.value])))
  );
});

const dimension1 = computed(() => inputData.value ? inputData.value.bubbleCaptions[0] : ENQUIRY_DIMENSION_1);
const dimension2 = computed(() => inputData.value ? inputData.value.bubbleCaptions[1] : ENQUIRY_DIMENSION_2);

const buttonLabel = computed(() => {
  const label1 = props.buttonLabels[0] || t('run_enquiry_with_1_dimension');
  const label2 = props.buttonLabels[1] || t('run_enquiry_with_2_dimensions');
  return activeBubbles.value.length > 1 ? label2 : label1;
});

// Methods
const filterBubbles = (area) => {
  const filtered = selectedArea.value === 'All' || area === selectedArea.value
    ? bubbleData.value.filter(bubble => bubble.area === area)
    : [];
  if (searchQuery.value) {
    return filtered.filter(bubble => bubble.title.toLowerCase().includes(searchQuery.value.toLowerCase()));
  }
  return filtered;
};

const isBubbleActive = (bubbleId) => {
  let isActive = false;
  if (props.drill && (isMatrix.value || isSalesVsGaps.value)) return isActive;

  activeBubbles.value.forEach(activeBubble => {
    if (Object.values(activeBubble).includes(bubbleId)) isActive = true;
  });

  return isActive;
};

const setBubbles = (bubble) => {
  if (!isFullPicture.value && !isVariance.value) {
    disableSelectBubblesPopup();
  }
  if (props.drill && (isMatrix.value || isSalesVsGaps.value)) {
    emit('drillSelected', { ...bubble });
    return;
  }
  if (bubble.checked && !isBubbleActive(bubble.id)) {
    activeBubbles.value.push({
      [bubbleAxis.value]: bubble.id,
    });
    emit(
      'changeStep',
      isSalesVsGaps.value ||
        (showSubmit.value &&
          !isSalesVsGaps.value &&
          activeBubbles.value.length > 1)
        ? 'end'
        : 'middle'
    );
    activeAreas.value.push(bubble.area);
    emit('bubbleClick', [bubble, 'active']);
  } else {
    const areaIndex = activeAreas.value.indexOf(bubble.area);
    if (areaIndex > -1) {
      activeAreas.value.splice(areaIndex, 1);
    }
    activeBubbles.value = activeBubbles.value.filter(
      (activeBubble) => !Object.values(activeBubble).includes(bubble.id)
    );
    if (getAxisParams.value && bubble.id === Object.values(vroute.query)[0]) {
      const newRoute = vrouter.resolve({ name: props.reportType });
      navigateToUrl(newRoute.href);
    }
    emit(
      'changeStep',
      activeBubbles.value.length ? 'middle' : 'start'
    );
    emit('bubbleClick', [bubble, 'inactive']);
  }
  bubbleRefreshKey.value++;
};

const getBubbleData = (bubble) => {
  const { id, title, area } = bubble;
  return {
    id,
    title,
    area,
    disabled: getDisabledConditional(area),
    clickable: true,
    description: false,
    xButtonEvent: false,
    checked: isBubbleActive(id),
  };
};

const getDisabledConditional = (area) => {
  const maximumBubbles = props.reportType === ReportTypes.PRODUCT_COMMUNITY_INSIGHTS ? 9999 : 2;
  if (isSalesVsGaps.value || isMatrix.value) {
    return activeBubbles.value.length === maximumBubbles;
  }
  const cond1 = activeBubbles.value.length === maximumBubbles,
    cond2 = dimensionsNumber.value === 2 && activeAreas.value.includes(area);
  return cond1 || cond2;
};

const goToReport = () => {
  if (iframe.value || props.drill) {
    emit('goToReport', enquiryParams.value);
    emit('drillSelected', activeBubbles.value);
    return;
  } else if (props.drill || !props.reportType) {
    emit('drillSelected', activeBubbles.value);
    return;
  }
  window.scrollTo(0, 0);
  let path = `${baseUrl}/${enquiryScope}/${props.reportType}/${reportScope}`;
  if (props.reportType === ReportTypes.PRODUCT_COMMUNITY_INSIGHTS) {
    path = `${baseUrl}/${alertsScope}/${reportScope}`;
  }
  const queryParams = new URLSearchParams({
    ...vroute.query,
    ...enquiryParams.value
  });
  navigateToUrl(`${path}?${queryParams.toString()}`);
};

const showBubble = (bubble) => {
  if (props.hiddenBubbles.includes(bubble.id)) return false;
  if (searchQuery.value.length < 2) return true;
  return checkTitleInSearch(bubble.title);
};

const checkTitleInSearch = (title) => {
  return title.toLowerCase().includes(searchQuery.value.toLowerCase());
};

const updateSearchBubbleQuery = (query) => {
  searchQuery.value = query;
  showAllBubbles.value = true;
};

const getIcon = (area) => {
  switch (area) {
  case BUBBLE_AREA_PRODUCT:
    return 'product';
  case BUBBLE_AREA_SALES:
    return 'opps';
  case BUBBLE_AREA_CALENDAR:
    return 'calendar';
  default:
    return 'user-circle';
  }
};

const getIconBackground = (area) => {
  switch (area) {
  case BUBBLE_AREA_CUSTOMER:
    return 'var(--colour-data-turmeric-dark)';
  case BUBBLE_AREA_PRODUCT:
    return 'var(--colour-data-viking-dark)';
  case BUBBLE_AREA_SALES:
    return 'var(--colour-data-barberry-dark)';
  case BUBBLE_AREA_CALENDAR:
    return 'var( --colour-data-puerto-rico-dark)';
  default:
    return 'var(--colour-utility-error)';
  }
};

const getFilteredBubbles = (area) => {
  let filteredBubbles = filterBubbles(area);
  filteredBubbles = filteredBubbles.filter(bubble => !props.hiddenBubbles.includes(bubble.id));
  if (!showAllBubbles.value && !isMobile.value) return filteredBubbles.slice(0, 5);
  return filteredBubbles;
};

const isExpandable = (area) => {
  const visibleBubblesInArea = bubbleData.value.filter(bubble =>
    bubble.area === area && !props.hiddenBubbles.includes(bubble.id)
  );
  return visibleBubblesInArea.length > 5;
};

// TODO: this is too complicated imo, why do we provide intialBubbles in different format to active bubbles?
onMounted(async () => {
  if (!bubbleData.value) await fetchBubbleData();
  if (props.initialActiveBubbles?.length) {
    props.initialActiveBubbles?.forEach(bubble => {
      activeBubbles.value.push({ [bubbleAxis.value]: bubble.id.toString() });
    });
  }
  if (getAxisParams.value && !props.drill) {
    setBubbles({
      checked: true,
      id: Object.values(vroute.query)[0],
      area: bubbleData.filter(bubble => bubble.id === Object.values(vroute.query)[0])[0].area,
    });
    bubbleRefreshKey.value++;
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.bubbles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: var(--spacing-2);

  &.report-page {
    padding-bottom: var(--spacing-8);
  }

  .section {
    flex-basis: 100%;
    margin-bottom: var(--spacing-2);
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      flex-basis: 50%;
      margin-bottom: var(--spacing-3);
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      flex-basis: 33%;
      margin-bottom: var(--spacing-3);
    }

    h3 {
      font-weight: var(--font-weight-semibold);
      margin-bottom: var(--spacing-1);
      padding-left: var(--spacing-half);
    }
   
    .view-more {
      margin-top: auto;
      justify-content: flex-start;
      padding-top: var(--spacing-2);
    }
  }

  .search {
    width: 100%;
    padding-bottom: var(--spacing-2);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 100%;
      max-width: 192px;
      left: var(--spacing-2);
      top: 0;
      padding-bottom: 0;
    }
  }

  .list {
    display: flex;
    width: 100%;
    margin-top: 0;
    margin-left: var(--spacing-1);
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 320px;
  }

  .option {
    display: flex;
    flex-basis: 100%;
    position: relative;
    margin-bottom: var(--spacing-1);

    label {
      flex-basis: 100%;
      display: flex;
      column-gap: var(--spacing-1);
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
    }

    &.active {
      :deep(.icon-circle) {
        box-shadow: 0 0 0 1px var(--colour-data-puerto-rico);
      }
    }
  }
}

.submit {
  background: var(--colour-panel-action);
  text-align: center;
  padding: var(--spacing-3);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  button {
    margin: 0 auto;
    padding: var(--spacing-1) var(--spacing-2);
  }
}

.no-results {
  padding: 0 var(--spacing-1);
}

.description {
  padding: var(--spacing-5);
  border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-color);

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    background: var(--background-color);
    bottom: calc(-1 * var(--spacing-half));
    height: var(--spacing-half);
  }

  a {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
    color: var(--colour-brand-deluge);
  }

  h3 {
    font-weight: var(--font-weight-semibold);
  }

  .close-button {
    position: absolute;
    top: var(--spacing-2);
    right: 0;
    height: 48px;
    width: 48px;
    box-shadow: none;

    :deep(.svg-container) {
      margin: 0 !important;
    }
  }
}

.bubble-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--spacing-2);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--spacing-6);
  height: var(--spacing-6);
  border-radius: 50%;
  min-width: var(--spacing-6);
  margin-right: var(--spacing-1);
}

.bubbles-container {
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--spacing-half) 1px var(--colour-panel-g-16);
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: flex;
    flex-direction: row;
  }
}

.uppercase {
  text-transform: uppercase;
}

.no-dimensions {
  font-size: var(--font-size-small);
}
</style>

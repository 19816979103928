<template>
  <h1
    ref="pageTitle"
    class="fw-bld text-3"
  >
    {{ title }}
  </h1>
  <Stage
    :items="stages"
    :selected-index="stage"
    @stage="changeStage"
  />
  <router-view
    @stage-update="stageUpdate"
  />
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import Stage from '@/shared/components/Stage/Stage.vue';
import { CLEAR_BY_ID, GET_BY_ID } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const vroute = useRoute();

const store = useStore();

let stagesNames = {
  'user_details': '',
  'role': 'role',
  'data_restrictions': 'data-restrictions',
};

const stage = ref(0);
const stageName = ref('user_details');
const stages = ['user_details', 'role', 'data_restrictions'];
const changeStage = newStage => {
  let changeStage = stages[newStage];
  let newIndex = stages.findIndex(s => s === newStage);
  if (!changeStage || newIndex === stage.value) return;
  let {
    params: { id },
  } = vroute;

  if (id) {
    navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${id}/edit/${stagesNames[changeStage]}`);
  } else if (vroute.params.addedit === 'add') {
    navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/add/${stagesNames[changeStage]}`);
  }
};

const selectedUser = computed(() => store.state.admin.users.selected.data);
const getUser = (params) => store.dispatch(`admin/users/${GET_BY_ID}`, params);
const clearUser = () => store.dispatch(`admin/users/${CLEAR_BY_ID}`);

const stageUpdate = (newStage) => {
  stageName.value = newStage;
  let newIndex = stages.findIndex(s => s === newStage);
  stage.value = newIndex;
};

const title = computed(() => vroute.params.addedit === 'add' 
  ? t('add_a_user') 
  : t('edit_name', { name: `${selectedUser.value.first_name} ${selectedUser.value.surname}` }));

onMounted(() => {
  if (vroute.params.id) {
    getUser({ id: vroute.params.id });
  }
});

onUnmounted(() => {
  clearUser();
});
</script>

<style lang="scss" scoped></style>

<template>
  <div class="dimensions-container">
    <div
      class="description"
      :style="{ '--background-color': steps[step].background }"
    >
      <IconBase
        icon-name="dimensions"
        :height="48"
        :width="48"
      />
      <h3>{{ steps[step].heading }}</h3>
      <a
        v-if="steps[step].href"
        :href="steps[step].href"
      >{{ steps[step].description }}</a>
      <p v-else>
        {{ steps[step].description }}
      </p>
    </div>
    <div class="bubbles-container">
      <BubbleList
        :button-labels="buttonLabels"
        :initial-active-bubbles="initialActiveBubbles"
        @change-step="changeStep"
        @drill-selected="completeStage"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { tAdmin as t } from '@sales-i/utils';
import { IconBase } from '@sales-i/dsv3';
import BubbleList from '@/intelligence/components/Bubble/BubbleList.vue';
import useTargets from '@/intelligence/composables/useTargets';

const emit = defineEmits(['completeStage']);

const props = defineProps({
  targetData: {
    type: Object,
    default: () => ({})
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
});

const { saveCurrentDims } = useTargets();

// data
const step = ref('start');
const steps = computed(() => ({
  start: {
    heading: t('select_enquiry_dimensions'),
    description: t('what_are_enquiry_dimensions'),
    background: '#FFF',
    href: '',
  },
  middle: {
    heading: t('want_another_dimension'),
    description: t('you_can_build_targets_with_two_dimensions'),
    background: '#F2F9FB',
  },
}));

const buttonLabels = computed(() => [t('build_target_with_1_dimension'), t('build_target')]);
const initialActiveBubbles = computed(() => 'dimensions' in props.targetData ? props.targetData.dimensions : []);

function changeStep(newStep) {
  step.value = newStep === 'end' ? 'middle' : newStep;
}

function completeStage(payload) {
  const { dimensions: targetDimensions } = props.targetData;
  const isPayloadLengthEqual = targetDimensions.length === payload.length;
  const dimensions = payload.map((dim, i) => {
    const id = Object.values(dim)[0];
    let values = [];
    let checkedAll = false;

    if (isPayloadLengthEqual && id == targetDimensions[i].id) {
      const allIdsMatch = payload.every((dim, i) => Object.values(dim)[0] == targetDimensions[i].id);

      if (allIdsMatch && props.isEdit && !targetDimensions[i].values.length) checkedAll = true;
      if (allIdsMatch) values = targetDimensions[i].values;
    }
    return { id, values, checkedAll };
  });

  saveCurrentDims(dimensions);
  emit('completeStage', { targetData: { dimensions }, newStage: 2 });
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.dimensions-container {
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 162px);
}

.description {
  padding: var(--spacing-2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-color);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-3);
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    background: var(--background-color);
    bottom: calc(-1 * var(--spacing-half));
    height: var(--spacing-half);
  }

  h3 {
    font-weight: var(--font-weight-semibold);
    text-align: center;
  }

  p,
  a {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
  }

  a {
    color: var(--colour-brand-deluge);
  }
}

.bubbles-container {
  background: var(--colour-panel-g-0);
  padding: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-2) var(--spacing-10) var(--spacing-3);
  }
}
</style>

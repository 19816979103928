<template>
  <div class="container flow px-4">
    <div>
      <CustomCard purpose="reversed">
        <h2>{{ t('languages') }}</h2>
        <h3><strong>{{ t('default_language') }}</strong>: {{ defaultLanguage?.native_name }}</h3>
        <Field
          v-if="languages"
          id="languages"
          :value="selectedLanguages"
          :label="t('select_available_languages')"
          type="multiselect"
          show-selected
          opened
          twoway
          :options="languageOptions"
          :error-message="t('please_select_language')"
          @input="setSelectedLanguages"
        />
      </CustomCard>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { tAdmin as t, getTenantLanguages, putTenantLanguages } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import { CustomCard } from '@sales-i/dsv3';
import useNotification from '@/shared/composables/useNotification';

const { sendSuccessAlert } = useNotification();

const languages = ref([]);
const languageOptions = computed(() => languages.value.filter(l => !l.default)
  .map(l => ({ 
    text: l.native_name, 
    value: l.locale, 
    selected: l.supported 
  })));
const selectedLanguages = computed(() => languageOptions.value.filter(option => option.selected));
const defaultLanguage = computed(() => languages.value.find(lang => lang.default));

onMounted(async () => {
  languages.value = await getTenantLanguages();
});

async function setSelectedLanguages(selected) {
  languages.value = languages.value.map(lang => ({
    ...lang,
    selected: selected.includes(lang),
  }));
  await putTenantLanguages(selected.map(lang => lang.value));
  languages.value = await getTenantLanguages();
  sendSuccessAlert(t('tenant_available_languages_updated'));
}
</script>

<style lang="scss" scoped>
.btn-group-bg {
  background-color: var(--colour-panel-action);
  padding: var(--spacing-2);

  .button {
    max-width: 200px;
  }
}
</style>
import { tGlobal as t } from '@sales-i/utils';
export const productSearchFilters = [
  {
    text: t('product_name'),
    value: 'product_name',
    path: 'product_name',
  },
  {
    text: t('product_code'),
    value: 'product_code',
    path: 'product_code',
  },
  {
    text: t('prod_group_level_1_desc'),
    value: 'prod_group_level_1_desc',
    path: 'prod_group_level_1_desc',
  },
  {
    text: t('prod_group_level_2_desc'),
    value: 'prod_group_level_2_desc',
    path: 'prod_group_level_2_desc',
  },
  {
    text: t('prod_group_level_3_desc'),
    value: 'prod_group_level_3_desc',
    path: 'prod_group_level_3_desc',
  },
  {
    text: t('prod_group_level_4_desc'),
    value: 'prod_group_level_4_desc',
    path: 'prod_group_level_4_desc',
  },
  {
    text: t('prod_group_level_5_desc'),
    value: 'prod_group_level_5_desc',
    path: 'prod_group_level_5_desc',
  },
];
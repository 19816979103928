import {
  SET_ITEMS,
  GET_BY_ENTITY,
  PATCH_BY_ENTITY,
  PATCH_BY_ENTITY_LOADING,
  PATCH_BY_ENTITY_ERROR,
} from '@/shared/store/actionType';
import { httpRequest, tAdmin as t } from '@sales-i/utils';
import namesakes from '@/admin/store/data/interactionFieldNamesakes';

export const patchByEntityGetters = {
  greatestCurrentOrderPos: (state) => {
    let positions = {
      type: 0,
      purpose: 0,
      outcome: 0,
      nextAction: 0,
    };
    Object.keys(namesakes).forEach(namesake => {
      if (state.data[namesake]) {
        state.data[namesake].forEach(item => {
          if (item.order_pos > positions[namesake]) {
            positions[namesake] = item.order_pos;
          }
        });
      }
    });
    return positions;
  }
};
// mutations
export const patchByEntityMutations = {
  [SET_ITEMS]: (state, { type, isNew, field, positions }) => {
    let currentOptions = state.data[type];
    if (isNew) {
      currentOptions.push({
        id: `FAKE-${type}-${state.managers[type].ticker}`,
        deletable: true,
        editable: true,
        name: `${t('custom_option')}-${positions[type] + 1}`,
        order_pos: positions[type] + 1,
        value: '',
      });
      state.managers[type].ticker++;
    } else {
      // non-strict compare to allow string numericals to match up
      let foundField = currentOptions.find(option => option.id == field.id);
      foundField.value = field.value;
    }
    state.data = {
      ...state.data,
      [type]: currentOptions,
    };
    state.loaded = true;
    state.loading = false;
  },
  [PATCH_BY_ENTITY]: (state) => {
    state.loaded = true;
    state.loading = false;
  },
  [PATCH_BY_ENTITY_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [PATCH_BY_ENTITY_ERROR]: (state) => {
    state.loaded = false;
    state.loading = false;
    state.errors = [];
  },
};

// actions
export const patchByEntityActions = {
  [SET_ITEMS]: async ({ commit, getters }, { type, isNew = true, field }) => {
    let positions = getters.greatestCurrentOrderPos;
    commit(SET_ITEMS, { type, isNew, field, positions });
  },
  [PATCH_BY_ENTITY]: async ({ commit, state, dispatch }, { type }) => {
    commit(PATCH_BY_ENTITY_LOADING);
    try {
      let currentOptions = state.data[type];
      let newOptions = [];
      currentOptions.forEach(item => {
        if (typeof item.id === 'string' && item.id.includes('FAKE-')) {
          let newOption = { ...item };
          delete newOption.id;
          // This causes problems down the line if we
          // don't set them to be identical
          newOption.name = newOption.value;
          newOptions.push(newOption);
        } else if (item.editable) {
          item.name = item.value;
          newOptions.push(item);
        }
      });
      const data = await httpRequest('patch', `config/schema/interactions/lists/${namesakes[type]}`, newOptions, {version: '2',});
      commit(PATCH_BY_ENTITY, data);
      dispatch(GET_BY_ENTITY, { entity: 'interactions', list_type: [namesakes[type]] });
    } catch(e) {
      commit(PATCH_BY_ENTITY_ERROR, e);
    }
  },
};

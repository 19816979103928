<template>
  <CustomCard
    purpose="reversed"
    class="details-container"
  >
    <h2>{{ name }}</h2>

    <CustomSelect
      :label="t('viewing_associations_at')"
      :value="type_filter"
      :items="filters"
      @on-change="changeAssociationType"
    />

    <CustomCard
      v-for="item in items"
      :key="`${item.antecedent_code}-${item.consequent_code}`"
      purpose="reversed"
      class="item"
      footer-color="grey"
    >
      <div class="main-area">
        <div
          v-if="item.muted || !showUpdateButton"
          class="muted"
        />
        <div class="header">
          <div class="associated-products-title">
            <div class="product-title">
              <div>
                <h3>{{ item.antecedent_name }}</h3>
                <span>{{ item.antecedent_code }}</span>
              </div>
              <div class="link-wrapper">
                <div class="link">
                  <div />
                  <div />
                  <div />
                </div>
                <RagChip
                  class="percent"
                  :value="item.confidence * 100"
                  suffix="%"
                  :green="67"
                  :amber="33"
                  small
                />
              </div>
              <div>
                <h3>{{ item.consequent_name }}</h3>
                <span>{{ item.consequent_code }}</span>
              </div>
            </div>
            <div class="grow" />
          </div>
        </div>

        <div
          v-if="industryTypes !== undefined && 'data' in industryTypes && industryTypes.data.length > 0"
          class="field-container"
        >
          <Field
            :small="false"
            type="checkbox"
            :label="t('apply_to_all')"
            :value="hasAllIndustryTypes(item, industryTypes.data.length)"
            @input="applyAllIndustryTypesToAssociation($event, item)"
          />

          <Field
            v-for="(industryType, index) in industryTypes.data"
            :id="industryType.industry_type_code"
            :key="industryType.industry_type_code"
            :label="industryType.industry_type_desc"
            :value="!!isIndustryTypeSelected(item, industryType)"
            :class="index > 9 && overFlowHidden.indexOf(item.rule_id) === -1 ? 'visually-hidden' : ''"
            :small="false"
            type="checkbox"
            @input="onCheckIndustryTypeInput($event, item, industryType)"
          />

          <CustomButton
            v-if="industryTypes.data.length > 10 && overFlowHidden.indexOf(item.rule_id) === -1"
            class="view-more-link"
            purpose="text"
            @click="() => overFlowHidden.push(item.rule_id)"
            @keydown.enter="() => overFlowHidden.push(item.rule_id)"
          >
            {{ t('view_more') }}
          </CustomButton>
        </div>
      </div>
      <template #footer>
        <ButtonGroup>
          <CustomButton
            v-if="showDismissButton"
            small
            purpose="reversed"
            @on-click="() => setMuted(item)"
          >
            {{ t('dismiss') }}
          </CustomButton>
          <CustomButton
            v-if="showUpdateButton && industryTypes?.data?.length"
            small
            purpose="action"
            @on-click="() => update(item)"
          >
            {{ t('update') }}
          </CustomButton>
        </ButtonGroup>
      </template> 
    </CustomCard>

    <Pagination
      :offset="offset"
      :limit="limit"
      :records-count="items.length"
      @page-changed="changePage"
    />
  </CustomCard>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { tAdmin as t } from '@sales-i/utils';
import { CustomCard, RagChip, CustomButton, CustomSelect } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import Field from '@/shared/components/Form/Field.vue';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import { usePagination, paginationProps, paginationEmits } from '@/shared/composables/usePagination';
import { ADD_INDUSTRY_TYPE_TO_ASSOCIATION, REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION } from '@/admin/store/actionType';
import { product_associations_admin } from '@/shared/store/data/policies';
import { scrollIntoView } from '@/shared/directives';

const props = defineProps({
  ...paginationProps,
  name: {
    type: String,
    default: '',
  },
  items: {
    type: Array,
    default: () => [],
  },
  industryTypes: {
    type: Object,
    default: () => {},
  },
  associationIndustryTypes: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['changeType', 'setMuted', 'update', ...paginationEmits]);

const store = useStore();

const { limit, offset, handlePageChanged } = usePagination(props, false, emit);

const removeIndustryType = (params) => store.dispatch(`admin/associations/${REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION}`, params);
const addIndustryType = (params) => store.dispatch(`admin/associations/${ADD_INDUSTRY_TYPE_TO_ASSOCIATION}`, params);      

const type_filter = ref('item_level');
const overFlowHidden = ref([]);

const permissions = computed(() => store.state.pbac.permissionsGranted);

const showDismissButton = computed(() => {
  return permissions.value[product_associations_admin] && permissions.value[product_associations_admin].delete;
});

const showUpdateButton = computed(() => {
  return permissions.value[product_associations_admin] && permissions.value[product_associations_admin].update;
});

const filters = computed(() => [
  {
    text: t('item_level'),
    value: 'item_level',
  },
  {
    text: t('group_level'),
    value: 'group_level',
  },
]);

const changeAssociationType = (value) => {
  type_filter.value = value;
  emit('changeType', value);
};

const setMuted = (item) => {
  emit('setMuted', item);
};

const update = (item) => {
  emit('update', item);
};

const applyAllIndustryTypesToAssociation = ($event, item) => {
  if ($event) {
    props.industryTypes.data.forEach(async type => {
      await removeIndustryType([item.rule_id, type]);
      await addIndustryType([item.rule_id, type]);
    });
  } else {
    props.industryTypes.data.forEach(async type => {
      await removeIndustryType([item.rule_id, type]);
    });
  }
};

const onCheckIndustryTypeInput = async ($event, item, industryType) => $event
  ? await addIndustryType([item.rule_id, industryType])
  : await removeIndustryType([item.rule_id, industryType]);

const isIndustryTypeSelected = (item, industryType) => {
  if (item.rule_id in props.associationIndustryTypes === false) {
    return;
  }
  return props.associationIndustryTypes[item.rule_id].findIndex(o => o.industry_type_code === industryType.industry_type_code) !== -1;
};

const hasAllIndustryTypes = (item, industryTypesCount) => {
  const associationIndustryTypes = props.associationIndustryTypes[item.rule_id];
  return associationIndustryTypes?.length === industryTypesCount;
};

const changePage = (e) => {
  scrollIntoView();
  handlePageChanged(e);
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.space-filler {
  flex-grow: 1;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: none;
  }
}

.details-container {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none;
}
.view-more-link {
  margin: 0 0 0 var(--spacing-3);
}

.details {
  margin-top: 0;
  padding: var(--spacing-3);
  background-color: var(--colour-panel-action);
  text-align: right;
}

.field-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  flex-wrap: wrap;
  padding-block-start: var(--spacing-3);
  .field {
    padding-inline: var(--spacing-half);
    margin-bottom: var(--spacing-1);
    &.visually-hidden {
      display: none;
    }
  }
}

.main-area {
  position: relative;
  .muted {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    z-index: 2;
    background-color: var(--colour-utility-black);
    border-top-left-radius: var(--border-radius-1);
    border-top-right-radius: var(--border-radius-1);
  }
}

.associated-products-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.product-title {
  h3 {
    font-weight: var(--font-weight-semibold);
  }
  span {
    font-size: var(--font-size-small);
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: flex;
  }
}

.link-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: var(--spacing-1);
  padding-block-end: var(--spacing-2);
  
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-block: var (--spacing-1);
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: var(--spacing-2);
  padding-block: var(--spacing-4);
  transform: rotate(90deg);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-inline: var(--spacing-2);
    padding-block-start: var(--spacing-1);
    padding-block-end: 0;
    transform: initial;
  }

  div:first-child,
  div:last-child {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: var (--colour-panel-g-16);
  }
  div:nth-child(2) {
    height: 2px;
    width: var (--spacing-6);
    background-color: var (--colour-panel-g-16);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: var (--spacing-5);
    }
  }
}

.percent {
  margin-top: calc(var(--spacing-2) * 1.6);
  position: absolute;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-top: var(--spacing-1);
    position: initial;
  }
}

.chart {
  margin: 0;
  padding: 0;
}
</style>
import {
  MOVE_STAGE,
} from '@/admin/store/actionType';
import { SET_VISIBLE } from '@/shared/store/actionType';
import { httpRequest, tAdmin as t } from '@sales-i/utils';
import { crm_admin } from '@/shared/store/data/policies';

// getters
export const menuGetters = {
  getMenuItems: (state, getters, rootState) => {
    let { permissionsGranted } = rootState.pbac;
    let defaultSectionsArr = [];
    let customSectionsArr = [];
    let { defaultSections, customSections } = getters.getSections;
    defaultSections.forEach((item, i) => {
      defaultSectionsArr.push({
        id: i,
        name: item.name,
        description: item.description,
        show: item.show,
        order_pos: item.order_pos
      });
    });

    customSections.forEach((item, i) => {
      customSectionsArr.push({
        id: i,
        name: item.name,
        description: item.description,
        show: item.show,
        order_pos: item.order_pos,
      });
    });

    let defaultSectionName;
    switch (state.entity) {
    case 'customers':
      defaultSectionName = t('customer_prospect_sections');
      break;
    case 'contacts':
      defaultSectionName = t('contact_sections');
      break;
    case 'opportunities':
      defaultSectionName = t('opportunity_sections');
      break;
    default:
      defaultSectionName = t('default_sections');
    }

    let defaultSection = {
      id: 1,
      name: defaultSectionName,
      sections: defaultSectionsArr,
    };

    let isCustomFields = ['files'].includes(state.entity);

    let customSection = {
      id: ['customers', 'prospects', 'interactions', 'contacts', 'opportunities'].includes(state.entity) ? 2 : 1,
      name: isCustomFields ? t('custom_fields') : t('custom_sections'),
      sections: isCustomFields ? null : customSectionsArr,
      isCustomSection: true,
      canCreateEntities: permissionsGranted[crm_admin] && permissionsGranted[crm_admin].create,
    };

    if (state.entity === 'files') return [customSection];
    if (state.entity === 'opportunities') {
      return [defaultSection, customSection, {
        id: 2,
        name: t('stages'),
      }];
    }
    if (state.entity === 'interactions') {
      return [{
        id: 2,
        name: t('interaction_fields'),
      }];
    }
    if (state.entity === 'company') {
      return [customSection, {
        id: 2,
        name: t('tags_report'),
      }];
    }
    if (['customers', 'prospects', 'contacts'].includes(state.entity)) {
      return [defaultSection, customSection];
    } 
    return [];
  }
};

// mutations
export const menuMutations = {
  [SET_VISIBLE]: (state, data) => {
    let sections = state.schema.sections;
    let section = sections.find(item => item.order_pos === data.order_pos);
    section.show = !data.show;
    state.schema = {
      ...state.schema,
      sections
    };
  },
  [MOVE_STAGE]: (state, sections) => {
    state.schema.sections = sections;
  },
};

// actions
export const menuActions = {
  [SET_VISIBLE]: async ({ commit }, data) => {
    commit(SET_VISIBLE, data);
  },
  [MOVE_STAGE]: async ({ commit, state, getters }, { from, to, }) => {
    if (from === to) return;

    // Access defaultSectionsArr from the getters
    let defaultSectionsArr = getters.getMenuItems[0].sections;

    let editingSections = [];
    // this hackery is to avoid mutating state outside of mutations
    state.schema.sections.forEach(section => {
      if (section.editable) {
        editingSections.push({
          ...section,
        });
      }
    });
    const movedItem = editingSections.splice(from, 1)[0];
    editingSections.splice(to, 0, movedItem);

    // Use the length of defaultSectionsArr as the offset
    editingSections.forEach((section, index) => {
      section.order_pos = index + defaultSectionsArr.length + 1;
    });

    // Create a new array with sections containing only desired properties
    const transformedSections = editingSections.map(({ description, id, name, order_pos }) => ({
      description,
      id,
      name,
      order_pos,
    }));

    // Create a new schema object with both default and modified sections
    const newSchema = {
      ...state.schema,
      sections: [...state.schema.sections.filter(section => !section.editable), ...editingSections],
    };

    // Transform the editingSections array but only include the customSections
    const transformedEditingSections = {
      entity: state.schema.entity,
      locale: 'en_GB',
      sections: transformedSections,
    };

    await httpRequest('patch', `config/schema/${state.entity}`, transformedEditingSections, { version: '2' });
    commit(MOVE_STAGE, newSchema.sections);
  },
};

import { getAllActions, getAllMutations } from './getAll';
import { getByIdActions, getByIdMutations } from './getById';
import { downloadByIdActions, downloadByIdMutations } from './downloadById';
import { deleteByIdActions, deleteByIdMutations } from './deleteById';
import { uploadActions, uploadMutations } from './upload';
import { tAdmin as t } from '@sales-i/utils';

// initial state
const state = () => ({
  all: {
    data: [],
    fields: [],
    loaded: false,
    loading: false,
    errors: [],
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    downloading: false,
    updated: false,
    errors: [],
  },
  sorting: [
    {
      text: t('customer_prospect_asc_des'),
      value: 'entity_id:asc',
    },
    {
      text: t('customer_prospect_des_asc'),
      value: 'entity_id:desc',
    },
    {
      text: t('value_asc_des'),
      value: 'value:asc',
    },
    {
      text: t('value_des_asc'),
      value: 'value:desc',
    },
    {
      text: t('decision_date_asc_des'),
      value: 'decision_date:asc',
    },
    {
      text: t('decision_date_des_asc'),
      value: 'decision_date:desc',
    },
    {
      text: t('probability_asc_des'),
      value: 'probability:asc',
    },
    {
      text: t('probability_des_asc'),
      value: 'probability:desc',
    },
  ],
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...getAllMutations,
  ...getByIdMutations,
  ...downloadByIdMutations,
  ...deleteByIdMutations,
  ...uploadMutations
};

// actions
const actions = {
  ...getAllActions,
  ...getByIdActions,
  ...downloadByIdActions,
  ...deleteByIdActions,
  ...uploadActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

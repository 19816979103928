<template>
  <CustomModal
    :title="`${user.data.first_name} ${user.data.last_name}` || 'User'"
    show-modal
    :max-width="800"
    @close-modal="closeModal"
  >
    <div class="pl-3 pr-3 pb-3">
      <h4 class="mb-1">
        {{ t('roles') }}
      </h4>
      <div
        v-for="role in user.data.roles"
        :key="role.id"
      >
        <CustomButton
          purpose="text"
          @on-click="goToRole(role.id)"
        >
          {{ role.name }}
        </CustomButton>
      </div>
      <h4 class="mt-2 mb-1">
        {{ t('email') }}
      </h4>
      <div>{{ user.data.email }}</div>
      <h4 class="mt-2 mb-1">
        {{ t('can_masquerade') }}
      </h4>
      <div>{{ user.data.permissions && user.data.permissions.can_masquerade ? t('yes') : t('no') }}</div>
      <h4 class="mt-2 mb-1">
        {{ t('user_status') }}
      </h4>
      <div>
        <Field
          v-if="user.data.status"
          id="status"
          type="switch"
          :value="user.data.status === 'ACTIVE'"
          twoway
          :label="t('by_making_this_user_in_active_they_will_be_disabled_from_logging_into_sales_i_you_will_still_be_charged_for_this_user')"
          @input="debouncedStatusChange"
        />
      </div>
    </div>
    <template #footer>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          small
          purpose="action"
          @click="editUser"
        >
          {{ t('edit') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import { debounce, tAdmin as t } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { navigateToUrl } from 'single-spa';
import { GET_BY_ID, PUT_BY_ID, CLEAR_BY_ID } from '@/shared/store/actionType';
import { SET_USER_STATUS, } from '@/admin/store/actionType';
import { baseUrl, companyArea, permissionsSection, rolesSection, usersSection } from '@/admin/router/urlBits';

const props = defineProps({
  id: {
    type: [String, Number],
    default: 0,
  }
});

const emit = defineEmits(['closeModal']);

const store = useStore();

const user = computed(() => store.state.admin.users.selected);
const getUser = params => store.dispatch(`admin/users/${GET_BY_ID}`, params);
const setUserStatus = params => store.dispatch(`admin/users/${SET_USER_STATUS}`, params);
const updateUser = params => store.dispatch(`admin/users/${PUT_BY_ID}`, params);
const clearUser = () => store.dispatch(`admin/users/${CLEAR_BY_ID}`);

const goToRole = id => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/${id}`);
};

const editUser = () => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${user.value.data.id}/edit`);
};

const handleChange = async(newValue) => {
  const currentVal = user.value.data.status === 'ACTIVE';
  if (newValue !== currentVal) {
    setUserStatus(newValue ? 'ACTIVE' : 'INACTIVE');
    await updateUser({ id: user.value.data.id, user: user.value.data });
    await getUser({ id: user.value.data.id });
  }
};

const debouncedStatusChange = debounce(value => handleChange(value), 500);

const closeModal = () => {
  emit('closeModal');
};

onMounted(() => {
  getUser({ id: props.id });
});

onUnmounted(() => {
  clearUser();
});
</script>

<style lang="scss" scoped>
.btn-group-bg {
  padding-top: 0;
  padding-bottom: 0;

  .button {
    margin-top: 0;
  }
}
</style>

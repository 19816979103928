<template>
  <div class="card-preview-container">
    <CustomCard
      purpose="alert"
      class="item"
    >
      <div class="header">
        <h4>{{ title }}</h4>
      </div>
      <div class="details">
        <Field
          :id="manager.id"
          :label="manager.label"
          :placeholder="manager.placeholder"
          type="text"
          required
          disabled
          :value="manager.value"
          :icon="{
            name: isMobile ? '' : 'edit-list',
            align: 'left',
            outside: !isMobile
          }"
        />
        <div>
          <div
            v-for="(item, i) in fields"
            :key="item.id"
            class="option-container"
          >
            <Field
              :id="item.id"
              :label="
                t('option_cardinal', {
                  cardinal: i + 1,
                })
              "
              :placeholder="t('select')"
              type="text"
              required
              :disabled="!item.editable"
              :value="item.value"
              :icon="
                item.deletable
                  ? {
                    name: 'trash',
                    size: 24,
                    align: 'right',
                    color: 'var(--colour-utility-action)',
                    func: () => deleteItem({ index: i, item, type: managerType }),
                  }
                  : {}
              "
              @valid="validateField"
            />
          </div>
          <div class="add-item-button-container">
            <CustomButton
              purpose="text"
              @on-click="addItem"
            >
              {{ t('add_list_item') }}
            </CustomButton>
          </div>
        </div>
      </div>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          purpose="reversed"
          :disabled="!isValid || saving"
          @on-click="save"
        >
          <template v-if="!saving">
            {{ t('update') }}
          </template>
          <template v-else>
            <BufferImage :size="24" />
          </template>
        </CustomButton>
      </ButtonGroup>
    </CustomCard>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';
import { tAdmin as t } from '@sales-i/utils';
import { BufferImage, CustomCard, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import Field from '@/shared/components/Form/Field.vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  manager: {
    type: Object,
    default: () => ({}),
  },
  managerType: {
    type: String,
    default: '',
  },
  fields: {
    type: Array,
    default: () => [],
  },
  saving: {
    type: Boolean
  }
});

const mq = useMq();

const emit = defineEmits(['save', 'addItem', 'editItem', 'deleteItem']);
const isValid = ref(false);

const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));

const validateField = field => {
  isValid.value = field.isValid;
  emit('editItem', field, props.managerType);
};
const addItem = () => emit('addItem');
const save = () => isValid.value && emit('save', props.managerType);
const deleteItem = deleteObj => emit('deleteItem', deleteObj);
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.item {
  padding: 0;
  margin-bottom: var(--spacing-4);
  .header {
    padding: var(--spacing-2);
    display: flex;
  }
  .details {
    gap: var(--spacing-3);
    margin-top: 0;
    padding: var(--spacing-3);
    background-color: var(--colour-panel-g-2);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: flex;
    }
    > :first-child,
    > :nth-child(2) {
      flex: 1;
    }
  }
}
.option-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2);
  .field {
    flex: 1;
  }
}
.radio-select {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.selected {
    border: 2px solid var(--colour-utility-focus);
    > div > div {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: var(--colour-utility-black);
    }
  }
  > div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--colour-panel-g-64);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.add-item-button-container {
  text-align: right;
  margin-bottom: var(--spacing-2);
  font-weight: var(--font-weight-semibold);
  :deep(.button) {
    display: inline;
  }
}
.btn-group-bg {
  margin-top: 0;
}
</style>
<style lang="scss">
.option-container .form-group .input-container button.icon.right {
  top: 9px;
}
</style>

import {
  FETCH_BUBBLE_DATA,
  FETCH_THROWAWAY_BUBBLE_DATA,
  CLEAR_REQUEST_PARAMETERS,
  SAVE_BUBBLE_DATA,
  SAVE_BUBBLE_AREAS,
  SET_DIMENSIONS_NUMBER,
  SAVE_BUBBLE_VALUES,
  FETCH_BUBBLE_VALUES,
  SET_LOADING,
} from '@/intelligence/store/actionType';
import { gqlRequest, ReportTypes } from '@sales-i/utils';
import { gql } from 'graphql-request';
import { RESPONSES_SET, REQUEST_ENTITY_BUBBLES } from '@/intelligence/store/data/apiInput';
const { MATRIX } = ReportTypes;

// getters
export const bubbleGetters = {};

// mutations
export const bubbleMutations = {
  [SAVE_BUBBLE_DATA]: (state, data) => {
    state.bubbleData = data;
  },
  [SAVE_BUBBLE_AREAS]: (state, data) => {
    state.bubbleAreas = data.map(bubble => bubble.area).filter((v, i, a) => a.indexOf(v) === i);
  },
  [CLEAR_REQUEST_PARAMETERS]: state => {
    state.bubblesRequestParameters.query = '';
  },
  [SET_LOADING]: (state, isLoading) => {
    state.bubblesLoading = isLoading;
  },
  [SET_DIMENSIONS_NUMBER]: (state, number) => {
    state.dimensionsNumber = number;
  },
  [SAVE_BUBBLE_VALUES]: (state, bubbleValues) => {
    state.bubbleValues = bubbleValues;
  },
};

// actions
export const bubbleActions = {
  [FETCH_BUBBLE_DATA]: async ({ commit, state }, nameQuery) => {
    try {
      const bubbleQuery = nameQuery || state.bubblesRequestParameters.query;
      commit(SET_LOADING, true);
      const 
        responseParams = RESPONSES_SET[REQUEST_ENTITY_BUBBLES],
        entity = REQUEST_ENTITY_BUBBLES,
        query = gql`{ ${entity}(query: "${bubbleQuery}") { ${responseParams} }}`,
        response = await gqlRequest(query, {}, { debug: true });
      commit(SAVE_BUBBLE_DATA, response[entity]);
      commit(SAVE_BUBBLE_AREAS, response[entity]);
    } catch (error) {
      console.error(error);
    } finally {
      commit(CLEAR_REQUEST_PARAMETERS);
      commit(SET_LOADING, false);
    }
  },
  [FETCH_THROWAWAY_BUBBLE_DATA]: async ({ state }, fieldQuery) => {
    try {
      const bubbleQuery = fieldQuery || state.bubblesRequestParameters.query;
      const 
        responseParams = RESPONSES_SET[REQUEST_ENTITY_BUBBLES],
        entity = REQUEST_ENTITY_BUBBLES,
        query = gql`{ ${entity}(field: "${bubbleQuery}") { ${responseParams} }}`,
        response = await gqlRequest(query, {}, { debug: true });
      return response.bubbles;
    } catch (error) {
      console.error(error);
    }
  },
  [FETCH_BUBBLE_VALUES]: async ({ commit }, [bubbleId, nameQuery = '']) => {
    try {
      commit(SET_LOADING, true);
      const 
        query = gql`{ bubblevalues(
          id: "${bubbleId}"
          query: "${nameQuery}"
          offset: 0,
          limit: 50
        )
          {    
            id
            value
          }
        }`,
        response = await gqlRequest(query, {}, { debug: true });
      commit(SAVE_BUBBLE_VALUES, response.bubblevalues);
      return response.bubblevalues;
    } catch (error) {
      console.error(error);
    } finally {
      commit(SET_LOADING, false);
    }
  },
  [SET_DIMENSIONS_NUMBER]: ({ commit }, scope) => {
    commit(SET_DIMENSIONS_NUMBER, scope === MATRIX ? 2 : 1);
  },
};

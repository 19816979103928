<template>
  <div class="group-list">
    <template v-if="!loading">
      <div
        v-for="(group, groupIndex) in groups"
        :key="groupIndex"
        class="group"
      >
        <slot
          name="groupItem"
          :group="group"
          :index="groupIndex"
        >
          <div class="group-header">
            <icon-base
              v-if="iconName"
              height="25"
              width="25"
              :icon-name="iconName"
              icon-color="black"
              class="icon"
            />
            <h2 class="px-1 fw-sbld text-2 group-by">
              {{ group }}
            </h2>
          </div>
          <div class="group-items-list">
            <template
              v-for="(item, index) in itemsWithGroups.filter(
                item => item[groupField] == group || (!item[groupField] && !group)
              )"
              :key="index"
            >
              <slot
                name="item"
                :item="item"
                :index="index"
                :title-field="titleField"
              >
                <TagChip
                  is-interactive
                  :class="getItemClass(item)"
                  @click.prevent="onItemClick(item)"
                >
                  {{ item[titleField] }}
                  <template #action>
                    <IconBase
                      class="ml-half"
                      :icon-name="has(item) ? 'check' : 'plus'"
                      :icon-color="has(item) ? 'var(--colour-utility-black)' : 'var(--colour-utility-action)'"
                      :height="16"
                      :width="16"
                    />
                  </template>
                </TagChip>
              </slot>
            </template>
          </div>
        </slot>
      </div>

      <div
        v-if="noItems"
        class="message"
      >
        <slot name="message">
          {{ t('no_items_to_show') }}
        </slot>
      </div>
    </template>

    <BufferImage
      v-else
      color="var(--colour-utility-black)"
      float="center"
    />
  </div>
</template>

<script>
import { BufferImage, IconBase, TagChip } from '@sales-i/dsv3';
import { tAdmin as t } from '@sales-i/utils';

export default {
  name: 'GroupList',
  components: {
    BufferImage,
    IconBase,
    TagChip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    groupField: {
      type: String,
      default: 'group',
    },
    titleField: {
      type: String,
      default: 'title',
    },
    keyField: {
      type: String,
      default: 'value',
    },
    iconName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
    tagLimit: {
      type: Number,
      default: 0,
    },
  },
  emits: ['itemClicked', 'itemsSelected'],
  data() {
    return {
      blob: this.selectedItems,
    };
  },
  computed: {
    noItems() {
      return !this.itemsWithGroups.length;
    },
    itemsWithGroups() {
      return this.items.map(item => ({
        [this.groupField]: (item[this.titleField] || '').charAt(0), // by default we take first letter
        ...item,
      }));
    },
    groups() {
      let groupsSet = new Set(this.itemsWithGroups.map(item => item.group));
      return Array.from(groupsSet.values()).sort();
    },
  },
  methods: {
    t,
    onItemClick(item) {
      if (this.multiSelect) {
        this.addOrDelete(item);
      }
      if (!this.multiSelect) {
        this.addOne(item);
      }
      this.$emit('itemClicked', item);
    },
    getItemClass(item) {
      return {
        selected: this.has(item),
      };
    },
    addOrDelete(item) {
      const itemIndex = this.indexOfItem(item);

      if (itemIndex === -1 && (this.selectedItems.length < this.tagLimit || !this.tagLimit)) {
        // Add tags as long as there are no more than the defined limit
        this.$emit('itemsSelected', [...this.selectedItems, item]);
      } else {
        this.$emit(
          'itemsSelected',
          this.selectedItems.filter((x, index) => index != itemIndex)
        );
      }
    },
    addOne(item) {
      this.$emit('itemsSelected', [item]);
    },
    indexOfItem(item) {
      const keyValue = item[this.keyField];
      return this.selectedItems.findIndex(x => x[this.keyField] === keyValue);
    },
    has(item) {
      return this.indexOfItem(item) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-header {
  display: flex;
  align-items: center;
  margin: var(--spacing-2) var(--spacing-2) 0 0;
}

.group-items-list {
  display: flex;
  flex-flow: row wrap;
}

.tag {
  cursor: pointer;
}

.tag.selected {
  background: var(--colour-utility-focus);
  color: var(--colour-utility-black);
}
</style>

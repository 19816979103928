<template>
  <CustomModal
    class="tag-report-details"
    title="Tag Report Details"
    @close-modal="$emit('closeModal')"
  >
    <div class="form-grid">
      <div
        v-for="(tag, tagIndex) in items"
        :key="`tag-${tagIndex}`"
        class="row"
      >
        <div class="col col-12 tag-wrapper">
          <h3 class="mt-2">
            #{{ tag.tag }}
          </h3>
          <p v-if="!tag.entities || !Object.keys(tag.entities).length">
            {{ t('no_entities_tagged') }}
          </p>
          <div
            v-for="(entity, enitityIndex) in Object.keys(tag.entities)"
            :key="`d-${tagIndex}-${enitityIndex}`"
            class="tag-details"
          >
            <h4 class="mt-1">
              {{ capitalize(entity, { onlyFirstLetter: true }) }}
            </h4>
            <table>
              <thead>
                <tr>
                  <th>{{ t('id') }}</th>
                  <th
                    v-for="(col, colIndex) in colHeaders[entity]"
                    :key="colIndex"
                  >
                    {{ capitalize(col.replace('_', ' ')) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="({ entity_id }, detailsIndex) in tag.entities[entity]"
                  :key="detailsIndex"
                >
                  <td>{{ entity_id }}</td>
                  <td v-if="entity === 'posts'">
                    {{ t('posts_are_not_supported_yet') }}
                  </td>
                  <td v-else-if="entity === 'file'">
                    {{ t('files_are_not_supported_yet') }}
                  </td>
                  <td
                    v-else-if="!details[entity][entity_id]"
                    :colspan="colHeaders[entity].length"
                  >
                    {{ t('loading') }}
                  </td>
                  <td
                    v-else-if="!Object.keys(details[entity][entity_id]).length"
                    :colspan="colHeaders[entity].length"
                  >
                    {{ t('not_found') }}
                  </td>
                  <template v-else>
                    <td
                      v-for="(col, colIndex) in colHeaders[entity]"
                      :key="colIndex"
                    >
                      {{ details[entity][entity_id][col] || '' }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <ButtonGroup>
        <CustomButton
          small
          purpose="reversed"
          @click="$emit('closeModal')"
        >
          {{ t('cancel') }}
        </CustomButton>
        <!-- <Button small>
          Export
        </Button> -->
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script>
import { tAdmin as t } from '@sales-i/utils';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import { mapActions, mapState } from 'vuex';
import { GET_BY_ID } from '@/shared/store/actionType';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { capitalize } from '@/shared/utils/strings';

export default {
  name: 'TagsReportDetailsModal',
  components: {
    CustomModal,
    CustomButton,
    ButtonGroup,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['closeModal'],
  data() {
    return {
      currentEntityType: '',
      colHeaders: {
        opportunities: ['entity_type', 'entity_name', 'value', 'probability', 'decision_date', 'status', 'next_step'],
        contacts: ['tagged_date'],
        customers: ['account_number', 'name', 'external_id'],
        prospects: ['account_number', 'name', 'external_id'],
        posts: [''],
        interactions: [''],
        file: [''],
      },
      details: {
        opportunities: {},
        contacts: {},
        customers: {},
        prospects: {},
        posts: {},
        interactions: {},
        file: {},
      },
    };
  },
  computed: {
    ...mapState({
      opportunities: state => state.crm.opportunities.selected.data,
      interactions: state => state.crm.interactions.selected.data,
      contacts: state => state.crm.contacts.selected.data,
      customers: state => state.crm.customers.selected.data,
      prospects: state => state.crm.prospects.selected.data,
    }),
  },
  async mounted() {
    // load all records by ids and cache them
    for (const { entities } of this.items) {
      for (const entityType of Object.keys(entities)) {
        if (!this[`get_${entityType}`]) continue;
        for (const { entity_id } of entities[entityType]) {
          if (!this.details[entityType][entity_id]) {
            await this[`get_${entityType}`]({
              id: entity_id,
              options: {
                version: '2',
                throwErrors: false,
              },
            });
            this.details[entityType][entity_id] = { ...this[entityType] };
          }
        }
      }
    }
  },
  methods: {
    t,
    ...mapActions({
      get_opportunities: `crm/opportunities/${GET_BY_ID}`,
      get_interactions: `crm/interactions/${GET_BY_ID}`,
      get_contacts: `crm/contacts/${GET_BY_ID}`,
      get_customers: `crm/customers/${GET_BY_ID}`,
      get_prospects: `crm/prospects/${GET_BY_ID}`,
    }),
    capitalize,
  },
};
</script>

<style lang="scss" scoped>
.tag-details {
  display: block;
  max-width: 100%;
  overflow: auto;
}

table {
  width: 100%;
}
th,
td {
  border: none;
}
.btn-group-bg {
  background-color: var(--colour-panel-action);
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
}
</style>

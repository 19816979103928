import { tAdmin as t } from '@sales-i/utils';

export const PERIOD_DAY = 'day';
export const PERIOD_WEEK = 'week';
export const PERIOD_PERIOD = 'period';
export const PERIOD_QUARTER = 'quarter';
export const PERIOD_HALF = 'half';
export const PERIOD_YEAR = 'year';

export const PERIODS = [
  {
    text: t(PERIOD_DAY),
    value: PERIOD_DAY,
  },
  {
    text: t(PERIOD_WEEK),
    value: PERIOD_WEEK,
  },
  {
    text: t(PERIOD_QUARTER),
    value: PERIOD_QUARTER,
  },
  {
    text: t(PERIOD_HALF),
    value: PERIOD_HALF,
  },
  {
    text: t(PERIOD_YEAR),
    value: PERIOD_YEAR,
  },
  {
    text: t(PERIOD_PERIOD),
    value: PERIOD_PERIOD,
  }
];

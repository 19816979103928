<template>
  <CRMCard
    v-if="position && isVisible"
    class="pagination-button"
    :class="[{ show: isVisible }, position]"
    :title="buttonTitle"
    :links="buttonLinks"
    @cancel="hide"
    @load="loadMore"
  >
    <template #info>
      {{ buttonText }}
    </template>
  </CRMCard>
</template>

<script setup>
import { computed } from 'vue';
import { CRMCard } from '@sales-i/dsv3';
import { tAdmin as t } from '@sales-i/utils';

const props = defineProps({
  position: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  limit: {
    type: Number,
    default: 50,
  },
});

const emit = defineEmits(['hide', 'setOffset']);

const buttonTitle = computed(() =>
  t('load_label', { label: props.label } )
);

const buttonText = computed(() =>
  t('the_report_loads_limit_results_at_a_time', { limit: props.limit })
);

const buttonLinks = computed(() => [
  {
    text: t('cancel'),
    emit: 'cancel',
  },
  {
    text: t('load'),
    emit: 'load',
  },
]);

function hide() {
  emit('hide', props.position);
}

function loadMore() {
  emit('setOffset', props.position);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.pagination-button {
  max-width: 350px;
  border-radius: 0;
  height: auto;
  max-height: 100px;
  z-index: 100;
  position: fixed;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
  }

  &.right {
    right: -370px;
    top: 50%;
    transform: translateY(-50%);
    transition: right 0.5s;

    &.show {
      right: var(--spacing-3);
    }
  }

  &.bottom {
    bottom: -150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: bottom 0.5s;
    position: absolute;

    &.show {
      bottom: var(--spacing-3);
    }
  }

  &.left {
    left: -370px;
    top: 50%;
    transform: translateY(-50%);
    transition: left 0.5s;

    &.show {
      left: var(--spacing-3);
    }
  }

  &.top {
    top: -150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: top 0.5s;
    position: absolute;

    &.show {
      top: var(--spacing-3);
    }
  }
}
</style>

<template>
  <div
    v-if="permissions.read"
    class="tag"
  >
    <template v-if="showAsDirectLink">
      <CustomButton
        type="button"
        icon
        tabindex="0"
        purpose="transparent"
        :width="32"
        :height="32"
        @click.stop="showTagList"
      >
        <IconBase
          icon-name="plus"
          icon-color="var(--colour-utility-action)"
          :width="32"
          :height="32"
        />
      </CustomButton>
    </template>
    <template v-else>
      <p>{{ t('tags') }}</p>
      <CustomButton
        type="button"
        icon
        tabindex="0"
        purpose="transparent"
        :width="32"
        :height="32"
        @click.stop="showTagList"
      >
        <IconBase
          icon-name="more"
          :width="16"
          :height="16"
        />
      </CustomButton>
    </template>
    <div
      v-if="disableOpen"
      class="block-content"
    />
  </div>
</template>

<script setup>
import { IconBase, CustomButton } from '@sales-i/dsv3';
import { useStore } from 'vuex';
import { SET_VISIBLE } from '@/shared/store/actionType';
import { tags } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { tAdmin as t } from '@sales-i/utils';

const { getPermissions } = usePermissions();
const permissions = getPermissions(tags);
const store = useStore();

const props = defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
  entity: {
    type: String,
    default: '',
  },
  disableOpen: {
    type: Boolean,
    default: false,
  },
  title : {
    type: String,
    default: 'Tags',
  },
  showAsDirectLink : {
    type: Boolean,
    default: false,
  }
});

const setVisible = params => store.dispatch(`tags/${SET_VISIBLE}`, params);

function showTagList() {
  setVisible({
    id: props.id,
    entity: props.entity,
  });
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.tag {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: visible;

  p {
    font-size: var(--font-size-small);
    line-height: 1rem;
    margin: 0;
    margin-top: 2px;
  }
  .block-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>

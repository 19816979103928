import CompanyTabsView from '@/admin/views/Company/CompanyTabsView.vue';
import TagsRoutes from './tags.js';
import LanguagesRoutes from './languages.js';
import StorageRoutes from './storage.js';
import PermissionsRoutes from './permissions';
import TargetsRoutes from './targets.js';
import EmailSyncRoutes from './emailsync.js';
import LogoRoutes from './logo.js';
import { baseUrl, companyArea as area, tagsSection, tagsReportSection } from '@/admin/router/urlBits';

export default [{
  path: `${baseUrl}/${area}`,
  redirect: `${baseUrl}/${area}/${tagsSection}/${tagsReportSection}`,
  component: CompanyTabsView,
  children: [
    {
      path: tagsSection,
      redirect: `${baseUrl}/${area}/${tagsSection}/${tagsReportSection}`,
    },
    ...TagsRoutes,
    ...LanguagesRoutes,
    ...StorageRoutes,
    ...PermissionsRoutes,
    ...TargetsRoutes,
    ...EmailSyncRoutes,
    ...LogoRoutes,
  ],
}];

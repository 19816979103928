import { tAdmin as t, ReportTypes } from '@sales-i/utils';

export const SALES_OVERVIEW_DASH_ID = -1;

export const FIXED_DASHBOARDS = [
  {
    id: SALES_OVERVIEW_DASH_ID,
    title: t('sales_overview'),
    description: t('overall_performance_insight'),
    category: 'sales-overview',
    fixed: true
  },
];

export const FIXED_WIDGETS = {
  ...FIXED_DASHBOARDS,
  widgets: [
    {
      id: -1,
      dashboard_id: SALES_OVERVIEW_DASH_ID,
      name: t('sales_overview'),
      description: '',
      report_type: ReportTypes.SALES_OVERVIEW,
      settings: '{}',
      request_params: '{}',
      order: -1,
      width: 3,
      height: 3,
      fixed: true,
      enabled: false,
      reportType: ReportTypes.SALES_OVERVIEW,
    },
  ],
};
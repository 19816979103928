import { ReportTypes } from '@sales-i/utils';
import {
  REPORT_AREA_PEOPLE,
  REPORT_AREA_PERFORMANCE,
  REPORT_AREA_PRODUCT,
  REPORT_AREA_CUSTOMER,
  REPORT_AREA_ENQUIRY,
  REPORT_AREA_AIML,
  REPORT_AREA_ALERTS
} from '@/intelligence/store/data/areas';
import {
  REQUEST_ENTITY_MATRIX,
  REQUEST_ENTITY_CUSTOMERS_SALES,
  REQUEST_ENTITY_PRODUCTS_SALES,
  REQUEST_ENTITY_PRODUCTS_SALES_GROUP,
  REPORTS_SORT_ASCENDING_KEY,
  REPORTS_SORT_DESCENDING_KEY,
  REQUEST_ENTITY_CUSTOMERS_DOWN_IN_REVENUE,
  REQUEST_ENTITY_CUSTOMERS_WITH_ZERO_SPEND,
  REQUEST_ENTITY_CUSTOMERS_DORMANT_YTD,
  REQUEST_ENTITY_CUSTOMERS_NOT_SPENT_THIS_PERIOD,
  REQUEST_ENTITY_CUSTOMERS_NOT_BUYING_TOP_PRODUCTS,
  REQUEST_ENTITY_CUSTOMERS_DECREASING_SPEND,
  REQUEST_ENTITY_TRENDS,
  REQUEST_ENTITY_VARIANCE,
  REQUEST_ENTITY_FULL_PICTURE,
  REQUEST_ENTITY_AIML_PRODUCT_TRENDS,
  REQUEST_ENTITY_QUERY_BUILDER,
  REQUEST_ENTITY_FREQUENT_PURCHASES,
  REQUEST_ENTITY_LATEST_PURCHASES,
  REQUEST_ENTITY_SALES_VS_GAPS,
  REQUEST_ENTITY_PRODUCT_GROUP_PURCHASE_ANALYSIS,
  REQUEST_ENTITY_ACCOUNTS_ANALYSIS,
  REQUEST_ENTITY_TRANSACTIONS_ANALYSIS,
  REQUEST_ENTITY_INTERACTION_SUMMARY,
  REPORTS_CHART_INITIAL_KEY1,
  REPORTS_CHART_INITIAL_KEY2,
  REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL,
  REQUEST_ENTITY_INTERACTION_SPEND_SUMMARY,
  REQUEST_ENTITY_CUSTOMER_ACTIVITY_LIST,
  REQUEST_ENTITY_MARKET_PENETRATION
} from '@/intelligence/store/data/apiInput';
import {
  ENQUIRY_AXES,
  ENQUIRY_DIMENSIONS,
  REPORTS_KPI_AMOUNT,
  REPORTS_KPI_CODE,
  REPORTS_KPI_CODE_TITLE,
  REPORTS_KPI_COST,
  REPORTS_KPI_COST_TITLE,
  REPORTS_KPI_COUNT,
  REPORTS_KPI_CUSTOMER,
  REPORTS_KPI_CUSTOMER_NAME_TITLE,
  REPORTS_KPI_CUSTOMER_TITLE,
  REPORTS_KPI_DESCRIPTION,
  REPORTS_KPI_DESCRIPTION_TITLE,
  REPORTS_KPI_MARGIN,
  REPORTS_KPI_MARGIN_TITLE,
  REPORTS_KPI_NAME,
  REPORTS_KPI_NAME_TITLE,
  REPORTS_KPI_PERCENT_DOWN,
  REPORTS_KPI_PERCENT_DOWN_TITLE,
  REPORTS_KPI_PREVIOUS_REVENUE,
  REPORTS_KPI_PREVIOUS_REVENUE_TITLE,
  REPORTS_KPI_PRODUCT_GROUP_NAME_TITLE,
  REPORTS_KPI_PRODUCT_NAME_TITLE,
  REPORTS_KPI_PROFIT,
  REPORTS_KPI_PROFIT_TITLE,
  REPORTS_KPI_QUANTITY,
  REPORTS_KPI_QUANTITY_TITLE,
  REPORTS_KPI_REVENUE,
  REPORTS_KPI_REVENUE_TITLE,
  REPORTS_KPI_SALES_VALUE,
  REPORTS_KPI_VALUE,
  REPORTS_KPI_VALUE_TITLE,
  REPORTS_KPI_ACCOUNT_NUMBER,
  REPORTS_KPI_ACCOUNT_NUMBER_TITLE,
  REPORTS_KPI_TOTAL_INTERACTIONS,
  REPORTS_KPI_TOTAL_INTERACTIONS_TITLE,
} from '@/intelligence/store/data/kpis';
import {
  REPORT_DATE_FILTER,
  REPORT_LIMIT_FILTER,
  REPORT_PERCENT_FROM_FILTER,
  REPORT_PERIODS_FILTER,
  REPORT_REVENUE_RANGE_FILTER_FROM,
  REPORT_REVENUE_RANGE_FILTER_TO
} from '@/intelligence/store/data/filters';
import {
  DATA_VISUALISATION_LINE_BAR,
  DATA_VISUALISATION_MULTI_BAR,
  DATA_VISUALISATION_PIE_CHART,
  DATA_VISUALISATION_TABLE_ONLY,
  TABLE_TYPE_MATRIX,
  TABLE_TYPE_TEMPLATE_REPORT,
  TABLE_TYPE_TRENDS,
  TABLE_TYPE_VARIANCE
} from '@/intelligence/store/data/charts';

import {
  intelligence_crm_reports,
  intelligence_enquiries,
  intelligence_insights,
  intelligence_products,
} from '@/shared/store/data/policies';

import { tAdmin as t } from '@sales-i/utils';
import { NEW_ALERT_ROUTE } from '@/intelligence/router/routeNames';


const {
  HIGHEST_SELLING_PRODUCTS,
  LOWEST_SELLING_PRODUCTS,
  HIGHEST_SELLING_PRODUCT_GROUP,
  LOWEST_SELLING_PRODUCT_GROUP,
  HIGHEST_MARGIN_PRODUCT_GROUP,
  LOWEST_MARGIN_PRODUCT_GROUP,
  HIGHEST_MARGIN_CUSTOMER,
  LOWEST_MARGIN_CUSTOMER,
  HIGHEST_REVENUE_CUSTOMER,
  LOWEST_REVENUE_CUSTOMER,
  HIGHEST_MARGIN_PRODUCT,
  LOWEST_MARGIN_PRODUCT,
  TRENDS,
  FULL_PICTURE,
  QUERY_BUILDER,
  MATRIX,
  SALES_VS_GAPS,
  VARIANCE,
  SALES_OPPORTUNITY,
  SALES_OPPORTUNITY_DEMO,
  INTERACTION_OUTCOMES,
  INTERACTION_OUTCOMES_DEMO,
  HIGHEST_SELLING_PRODUCTS_TITLE,
  LOWEST_SELLING_PRODUCTS_TITLE,
  HIGHEST_SELLING_PRODUCT_GROUP_TITLE,
  LOWEST_SELLING_PRODUCT_GROUP_TITLE,
  HIGHEST_MARGIN_PRODUCT_GROUP_TITLE,
  LOWEST_MARGIN_PRODUCT_GROUP_TITLE,
  HIGHEST_MARGIN_CUSTOMER_TITLE,
  LOWEST_MARGIN_CUSTOMER_TITLE,
  HIGHEST_REVENUE_CUSTOMER_TITLE,
  LOWEST_REVENUE_CUSTOMER_TITLE,
  HIGHEST_MARGIN_PRODUCT_TITLE,
  LOWEST_MARGIN_PRODUCT_TITLE,
  TRENDS_TITLE,
  FULL_PICTURE_TITLE,
  MATRIX_TITLE,
  SALES_VS_GAPS_TITLE,
  VARIANCE_TITLE,
  CUSTOMER_DOWN_ON_SPEND,
  CUSTOMER_DOWN_ON_SPEND_TITLE,
  CUSTOMER_WITH_ZERO_SPEND,
  CUSTOMER_WITH_ZERO_SPEND_TITLE,
  CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN,
  CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN_TITLE,
  CUSTOMER_WHO_SPENT_IN_PREVIOUS,
  CUSTOMER_WHO_SPENT_IN_PREVIOUS_TITLE,
  CUSTOMER_DORMANT_YTD,
  CUSTOMER_DORMANT_YTD_TITLE,
  CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT,
  CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT_TITLE,
  CUSTOMER_NOT_BUYING_TOP_PRODUCTS,
  CUSTOMER_NOT_BUYING_TOP_PRODUCTS_TITLE,
  CUSTOMER_DECREASING_SPEND_PERIOD,
  CUSTOMER_DECREASING_SPEND_PERIOD_TITLE,
  AIML_PRODUCT_TRENDS,
  AIML_PRODUCT_TRENDS_TITLE,
  TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE,
  TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE_TITLE,
  TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED_TITLE,
  TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED,
  TOP_PRODUCTS_BY_PURCHASE_VALUE_TITLE,
  TOP_PRODUCTS_BY_PURCHASE_VALUE,
  TOP_PRODUCTS_BY_PURCHASE_QUANTITY,
  TOP_PRODUCTS_BY_PURCHASE_QUANTITY_TITLE,
  PRODUCTS_PURCHASED_FREQUENTLY,
  PRODUCTS_PURCHASED_FREQUENTLY_TITLE,
  PRODUCT_GROUP_PURCHASE_ANALYSIS,
  PRODUCT_GROUP_PURCHASE_ANALYSIS_TITLE,
  MONTHLY_PURCHASES_BY_VALUE,
  MONTHLY_PURCHASES_BY_VALUE_TITLE,
  MONTHLY_PURCHASES_BY_QUANTITY,
  MONTHLY_PURCHASES_BY_QUANTITY_TITLE,
  LATEST_PURCHASES_BY_VALUE,
  LATEST_PURCHASES_BY_VALUE_TITLE,
  LATEST_PURCHASES_BY_QUANTITY,
  LATEST_PURCHASES_BY_QUANTITY_TITLE,
  PURCHASES_VS_GAPS_BY_PURCHASE_VALUE,
  PURCHASES_VS_GAPS_BY_PURCHASE_VALUE_TITLE,
  PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY,
  PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY_TITLE,
  VARIANCE_ENQUIRY_BY_PURCHASE_VALUE,
  VARIANCE_ENQUIRY_BY_PURCHASE_VALUE_TITLE,
  VARIANCE_ENQUIRY_BY_QUANTITY,
  VARIANCE_ENQUIRY_BY_QUANTITY_TITLE,
  ACCOUNTS_ANALYSIS,
  ACCOUNTS_ANALYSIS_TITLE,
  SALES_OPPORTUNITY_TITLE,
  SALES_OPPORTUNITY_DEMO_TITLE,
  INTERACTION_OUTCOMES_TITLE,
  INTERACTION_OUTCOMES_DEMO_TITLE,
  TRANSACTIONS_ANALYSIS,
  TRANSACTIONS_ANALYSIS_TITLE,
  PRODUCT_ASSOCIATIONS_INSIGHTS,
  PRODUCT_ASSOCIATIONS_INSIGHTS_TITLE,
  PRODUCT_COMMUNITY_INSIGHTS,
  ALERT_BUILDER_INSIGHTS_TITLE,
  SALES_REP_LEADERBOARD,
  SALES_REP_LEADERBOARD_TITLE,
  HIGH_REVENUE_SELLERS,
  HIGH_REVENUE_SELLERS_TITLE,
  MOST_IMPROVED,
  MOST_IMPROVED_TITLE,
  REP_PRODUCTIVITY,
  REP_PRODUCTIVITY_TITLE,
  REPS_WITH_GROWTH_POTENTIAL,
  REPS_WITH_GROWTH_POTENTIAL_TITLE,
  REPS_THAT_NEED_SUPPORT,
  REPS_THAT_NEED_SUPPORT_TITLE,
  INTERACTION_OUTCOMES_DRILL,
  CUSTOMER_SNAPSHOT,
  CUSTOMER_SNAPSHOT_TITLE,
  INTERACTION_SPEND_SUMMARY,
  INTERACTION_SPEND_SUMMARY_TITLE,
  PERFORMANCE_SNAPSHOT,
  PERFORMANCE_SNAPSHOT_TITLE,
  PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART,
  BRANCH_SNAPSHOT,
  BRANCH_SNAPSHOT_TITLE,
  QUERY_BUILDER_TITLE,
  MARKET_PENETRATION,
  // DEMO
  CUSTOMER_ACTIVITY,
  CUSTOMER_ACTIVITY_TITLE,
  MARKET_PENETRATION_TITLE,
} = ReportTypes;

// standard currency
export const CURRENCY_SYMBOL = true;
export const PERCENT_SYMBOL = '%';

const snapshotPolicies = [intelligence_insights, intelligence_products, intelligence_enquiries];

const templateReportSet = {
  /* PRODUCT
  =================================*/
  [HIGHEST_SELLING_PRODUCTS]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: HIGHEST_SELLING_PRODUCTS,
    title: HIGHEST_SELLING_PRODUCTS_TITLE,
    description: t('discover_the_highest_quantity_products_you_re_selling_by_individual_product_name'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES,
    policy: intelligence_products,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_AMOUNT,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        colorSet: 1,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PROFIT,
        name: REPORTS_KPI_PROFIT_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_AMOUNT,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [LOWEST_SELLING_PRODUCTS]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: LOWEST_SELLING_PRODUCTS,
    title: LOWEST_SELLING_PRODUCTS_TITLE,
    description: t('products_with_the_lowest_quantity_sold_do_your_sales_team_love_a_challenge'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES,
    policy: intelligence_products, 
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_AMOUNT,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        colorSet: 1,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PROFIT,
        name: REPORTS_KPI_PROFIT_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_AMOUNT,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [HIGHEST_SELLING_PRODUCT_GROUP]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: HIGHEST_SELLING_PRODUCT_GROUP,
    title: HIGHEST_SELLING_PRODUCT_GROUP_TITLE,
    description: t('discover_the_cash_cow_of_your_business_and_all_the_products_that_fall_under_its_umbrella'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES_GROUP,
    policy: intelligence_products,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_GROUP_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_AMOUNT,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        colorSet: 1,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PROFIT,
        name: REPORTS_KPI_PROFIT_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_AMOUNT,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [LOWEST_SELLING_PRODUCT_GROUP]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: LOWEST_SELLING_PRODUCT_GROUP,
    title: LOWEST_SELLING_PRODUCT_GROUP_TITLE,
    description: t('category_with_the_slowest_sales_velocity_slow_and_steady_wins_the_race_but_what_about_the_inventory_cost'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES_GROUP,
    policy: intelligence_products,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_GROUP_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_AMOUNT,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        colorSet: 1,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PROFIT,
        name: REPORTS_KPI_PROFIT_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_AMOUNT,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [HIGHEST_MARGIN_PRODUCT_GROUP]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: HIGHEST_MARGIN_PRODUCT_GROUP,
    title: HIGHEST_MARGIN_PRODUCT_GROUP_TITLE,
    description: t('what_category_has_the_highest_yielding_products'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES_GROUP,
    policy: intelligence_products,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_GROUP_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_MARGIN,
        name: REPORTS_KPI_MARGIN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 1,
        includesChart: true,
        includesChip: false
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_MARGIN,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [LOWEST_MARGIN_PRODUCT_GROUP]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: LOWEST_MARGIN_PRODUCT_GROUP,
    title: LOWEST_MARGIN_PRODUCT_GROUP_TITLE,
    description: t('what_category_has_the_lowest_yielding_products'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES_GROUP,
    policy: intelligence_products,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_GROUP_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_MARGIN,
        name: REPORTS_KPI_MARGIN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 1,
        includesChart: true,
        includesChip: false
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_MARGIN,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [HIGHEST_MARGIN_PRODUCT]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: HIGHEST_MARGIN_PRODUCT,
    title: HIGHEST_MARGIN_PRODUCT_TITLE,
    description: t('which_products_yield_the_highest_contribution_per_unit_what_should_your_sales_team_be_focused_on'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES,
    policy: intelligence_products, 
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_MARGIN,
        name: REPORTS_KPI_MARGIN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 1,
        includesChart: true,
        includesChip: false
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_MARGIN,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [LOWEST_MARGIN_PRODUCT]: {
    area: REPORT_AREA_PRODUCT,
    urlCode: LOWEST_MARGIN_PRODUCT,
    title: LOWEST_MARGIN_PRODUCT_TITLE,
    description: t('which_products_yield_the_lowest_contribution_per_unit_what_loss_leaders_could_you_be_using_to_close_a_deal'),
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES,
    policy: intelligence_products, 
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_MARGIN,
        name: REPORTS_KPI_MARGIN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 1,
        includesChart: true,
        includesChip: false
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_MARGIN,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [PRODUCT_ASSOCIATIONS_INSIGHTS]: {
    urlCode: PRODUCT_ASSOCIATIONS_INSIGHTS,
    area: REPORT_AREA_PRODUCT,
    title: PRODUCT_ASSOCIATIONS_INSIGHTS_TITLE,
    description: t('view_product_association_reports_from_a_i_m_l'),
    requestEntity: REQUEST_ENTITY_AIML_PRODUCT_TRENDS,
    policy: intelligence_products
  },

  /* CUSTOMER
  =================================*/
  [MARKET_PENETRATION]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: MARKET_PENETRATION,
    title: MARKET_PENETRATION_TITLE,
    description: t('analyse_penetration_against_target'),
    linkText: t('open_matrix'),
    dateRangeNumber: 1,
    requestEntity: REQUEST_ENTITY_MARKET_PENETRATION,
    bubbleCaptions: ENQUIRY_AXES,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
  },
  [CUSTOMER_ACTIVITY]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_ACTIVITY,
    title: CUSTOMER_ACTIVITY_TITLE,
    description: t('analyse_customer_interactions'),
    requestEntity: REQUEST_ENTITY_CUSTOMER_ACTIVITY_LIST,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_CUSTOMER_TITLE,
      },
      {
        key: REPORTS_KPI_ACCOUNT_NUMBER,
        name: REPORTS_KPI_ACCOUNT_NUMBER_TITLE,
      },
      {
        key: 'avg_sales_value',
        name: t('avg_sales_value'),
        prefix: CURRENCY_SYMBOL,
      },
      {
        key: 'sales_value_1',
        name: t('sales_value'),
        prefix: CURRENCY_SYMBOL,
      },
      {
        key: 'sales_value_variance',
        name: t('last_period'),
        suffix: PERCENT_SYMBOL,
      },
      {
        key: 'gross_profit_1',
        name: t('gross_profit'),
        suffix: PERCENT_SYMBOL,
      },
      {
        key: 'interaction_count',
        name: t('interactions'),
      },
      {
        key: 'avg_interaction_count_per_wd',
        name: t('average_per_day'),
      },
    ],
    defaultSortHeader: 'avg_sales_value',
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    dateRangeNumber: 2,
  },
  [HIGHEST_REVENUE_CUSTOMER]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: HIGHEST_REVENUE_CUSTOMER,
    title: HIGHEST_REVENUE_CUSTOMER_TITLE,
    description: t('who_s_your_biggest_customer_by_revenue_and_do_they_have_any_friends'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_SALES,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_CUSTOMER_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PROFIT,
        name: REPORTS_KPI_PROFIT_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_REVENUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [LOWEST_REVENUE_CUSTOMER]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: LOWEST_REVENUE_CUSTOMER,
    title: LOWEST_REVENUE_CUSTOMER_TITLE,
    description: t('who_s_your_smallest_customer_by_revenue_are_they_open_to_cross_selling_opportunities'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_SALES,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_CUSTOMER_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PROFIT,
        name: REPORTS_KPI_PROFIT_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_REVENUE,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [HIGHEST_MARGIN_CUSTOMER]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: HIGHEST_MARGIN_CUSTOMER,
    title: HIGHEST_MARGIN_CUSTOMER_TITLE,
    description: t('which_customers_repeatedly_purchases_the_highest_margin_products'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_SALES,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_CUSTOMER_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_MARGIN,
        name: REPORTS_KPI_MARGIN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 1,
        includesChart: true,
        includesChip: false
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_MARGIN,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_DATE_FILTER],
  },
  [LOWEST_MARGIN_CUSTOMER]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: LOWEST_MARGIN_CUSTOMER,
    title: LOWEST_MARGIN_CUSTOMER_TITLE,
    description: t('where_are_your_upselling_opportunities'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_SALES,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_CUSTOMER_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_MARGIN,
        name: REPORTS_KPI_MARGIN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 1,
        includesChart: true,
        includesChip: false
      },
      {
        key: REPORTS_KPI_COST,
        name: REPORTS_KPI_COST_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 3,
        includesChart: true,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: true,
        includesChip: true
      }
    ],
    defaultSortHeader: REPORTS_KPI_MARGIN,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [REPORT_DATE_FILTER]
  },
  [CUSTOMER_DOWN_ON_SPEND]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_DOWN_ON_SPEND,
    title: CUSTOMER_DOWN_ON_SPEND_TITLE,
    description: t('know_which_of_my_customer_are_not_spending'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_DOWN_IN_REVENUE,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: false,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PREVIOUS_REVENUE,
        name: REPORTS_KPI_PREVIOUS_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: false,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PERCENT_DOWN,
        name: REPORTS_KPI_PERCENT_DOWN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 3,
        includesChart: false,
        includesChip: false
      }
    ],
    defaultSortHeader: REPORTS_KPI_REVENUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [{...REPORT_PERCENT_FROM_FILTER, prefix: t('reduced_by')}]
  },
  [CUSTOMER_WHO_SPENT_IN_PREVIOUS]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_WHO_SPENT_IN_PREVIOUS,
    title: CUSTOMER_WHO_SPENT_IN_PREVIOUS_TITLE,
    description: t('compare_and_track_my_customers_spending'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_DOWN_IN_REVENUE,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_PREVIOUS_REVENUE,
        name: t('revenue_last_year'),
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 2,
        includesChart: false,
        includesChip: true
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: t('revenue_this_year'),
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: false,
        includesChip: true
      },
    ],
    defaultSortHeader: REPORTS_KPI_PREVIOUS_REVENUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_REVENUE_RANGE_FILTER_FROM, REPORT_REVENUE_RANGE_FILTER_TO]
  },
  [CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN,
    title: CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN_TITLE,
    description: t('know_my_top_accounts_customers_that_are_down_in_revenue'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_DOWN_IN_REVENUE,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: false,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PERCENT_DOWN,
        name: REPORTS_KPI_PERCENT_DOWN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 3,
        includesChart: false,
        includesChip: false
      }
    ],
    defaultSortHeader: REPORTS_KPI_REVENUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_LIMIT_FILTER, {...REPORT_PERCENT_FROM_FILTER, suffix: t('reduced_spend')}]
  },
  [CUSTOMER_DORMANT_YTD]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_DORMANT_YTD,
    title: CUSTOMER_DORMANT_YTD_TITLE,
    description: t('know_which_of_my_customers_haven_t_spent_or_made_any_transaction_y_t_d_but_spending_this_current_period'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_DORMANT_YTD,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: 'revenue_this_period',
        name: t('revenue_y_t_d'),
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: false,
        includesChip: true
      },
    ],
    defaultSortHeader: 'revenue_this_period',
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: []
  },
  [CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT,
    title: t(CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT_TITLE),
    description: t('know_and_track_my_customer_spend_in_the_past_6_months'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_NOT_SPENT_THIS_PERIOD,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_PREVIOUS_REVENUE,
        name: t('revenue_y_t_d'),
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: false,
        includesChip: true
      },
    ],
    defaultSortHeader: REPORTS_KPI_PREVIOUS_REVENUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_PERIODS_FILTER]
  },
  [CUSTOMER_WITH_ZERO_SPEND]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_WITH_ZERO_SPEND,
    title: t(CUSTOMER_WITH_ZERO_SPEND_TITLE),
    description: t('compare_and_track_customers_that_have_not_spent_at_all_in_the_past_6_12_months'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_WITH_ZERO_SPEND,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      }
    ],
    defaultSortHeader: REPORTS_KPI_CUSTOMER,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [{...REPORT_PERIODS_FILTER, prefix: t('last')}]
  },
  [CUSTOMER_NOT_BUYING_TOP_PRODUCTS]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_NOT_BUYING_TOP_PRODUCTS,
    title: t(CUSTOMER_NOT_BUYING_TOP_PRODUCTS_TITLE),
    description: t('know_which_of_my_accounts_did_not_buy_my_most_profitable_products'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_NOT_BUYING_TOP_PRODUCTS,
    policy: [intelligence_insights, intelligence_products],
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      }
    ],
    defaultSortHeader: REPORTS_KPI_CUSTOMER,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
    filters: [REPORT_LIMIT_FILTER, REPORT_PERIODS_FILTER]
  },
  [CUSTOMER_DECREASING_SPEND_PERIOD]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_DECREASING_SPEND_PERIOD,
    title: t(CUSTOMER_DECREASING_SPEND_PERIOD_TITLE),
    description: t('know_my_churn_risks'),
    requestEntity: REQUEST_ENTITY_CUSTOMERS_DECREASING_SPEND,
    policy: intelligence_insights,
    visualInput: [
      {
        key: REPORTS_KPI_CUSTOMER,
        name: REPORTS_KPI_CUSTOMER_TITLE,
        prefix: '',
        suffix: '',
        includesChart: false,
        includesChip: false
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        colorSet: 1,
        includesChart: false,
        includesChip: true
      },
      {
        key: REPORTS_KPI_PERCENT_DOWN,
        name: REPORTS_KPI_PERCENT_DOWN_TITLE,
        prefix: '',
        suffix: PERCENT_SYMBOL,
        colorSet: 3,
        includesChart: false,
        includesChip: false
      }
    ],
    defaultSortHeader: REPORTS_KPI_REVENUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    filters: [REPORT_PERIODS_FILTER]
  },
  [CUSTOMER_SNAPSHOT]: {
    area: REPORT_AREA_CUSTOMER,
    urlCode: CUSTOMER_SNAPSHOT,
    title: t(CUSTOMER_SNAPSHOT_TITLE),
    description: t('everything_you_need_to_know_about_your_customer'),
    policy: snapshotPolicies,
  },
};

/* ENQUIRIES
=================================*/
const enquirySet = {
  [TRENDS]: {
    area: REPORT_AREA_ENQUIRY,
    urlCode: TRENDS,
    title: t(TRENDS_TITLE),
    description: t('compare_your_chosen_metrics_over_time_with_your_results_last_year'),
    linkText: t('open_trends'),
    dateRangeNumber: 2,
    requestEntity: REQUEST_ENTITY_TRENDS,
    prefix: CURRENCY_SYMBOL,
    policy: intelligence_enquiries
  },
  [MATRIX]: {
    area: REPORT_AREA_ENQUIRY,
    urlCode: MATRIX,
    title: t(MATRIX_TITLE),
    description: t('compare_any_two_bubbles_on_an_x_and_y_axis'),
    linkText: t('open_matrix'),
    dateRangeNumber: 1,
    requestEntity: REQUEST_ENTITY_MATRIX,
    bubbleCaptions: ENQUIRY_AXES,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
  },
  [SALES_VS_GAPS]: {
    area: REPORT_AREA_ENQUIRY,
    urlCode: SALES_VS_GAPS,
    title: t(SALES_VS_GAPS_TITLE),
    description: t('see_a_breakdown_of_your_sales_in_intervals_from_every_day_to_every_year'),
    linkText: t('open_sales_vs_gaps'),
    dateRangeNumber: 1,
    requestEntity: REQUEST_ENTITY_SALES_VS_GAPS,
    bubbleCaptions: ENQUIRY_DIMENSIONS,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
    policy: intelligence_enquiries
  },
  [VARIANCE]: {
    area: REPORT_AREA_ENQUIRY,
    urlCode: VARIANCE,
    title: t(VARIANCE_TITLE),
    description: t('how_does_your_performance_this_year_compare_to_your_performance_this_time_last_year'),
    linkText: t('open_variance'),
    dateRangeNumber: 2,
    requestEntity: REQUEST_ENTITY_VARIANCE,
    policy: intelligence_enquiries,
    bubbleCaptions: ENQUIRY_DIMENSIONS,
    defaultSortHeader: `${REPORTS_KPI_SALES_VALUE}_variance`,
    defaultSortOption: REPORTS_SORT_ASCENDING_KEY,
  },
  [FULL_PICTURE]: {
    area: REPORT_AREA_ENQUIRY,
    urlCode: FULL_PICTURE,
    title: t(FULL_PICTURE_TITLE),
    description: t('configure_your_own_report_with_your_bubbles'),
    linkText: t('open_full_picture'),
    dateRangeNumber: 1,
    requestEntity: REQUEST_ENTITY_FULL_PICTURE,
    policy: intelligence_enquiries,
    bubbleCaptions: ENQUIRY_DIMENSIONS,
    defaultSortHeader: REPORTS_KPI_SALES_VALUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
  },
  [AIML_PRODUCT_TRENDS]: {
    area: REPORT_AREA_AIML,
    urlCode: AIML_PRODUCT_TRENDS,
    title: t(AIML_PRODUCT_TRENDS_TITLE),
    description: t('fetch_an_a_i_m_l_report_to_compare_performance_between_2_products'),
    dateRangeNumber: 2,
    requestEntity: REQUEST_ENTITY_AIML_PRODUCT_TRENDS,
    policy: intelligence_products
  },
  [QUERY_BUILDER]: {
    area: REPORT_AREA_AIML,
    urlCode: QUERY_BUILDER,
    title: t(QUERY_BUILDER_TITLE),
    description: t('query_the_communities_generated_by_a_i_m_l'),
    linkText: t('open_query_builder'),
    dateRangeNumber: 1,
    requestEntity: REQUEST_ENTITY_QUERY_BUILDER,
    bubbleCaptions: ENQUIRY_DIMENSIONS,
    defaultSortHeader: REPORTS_KPI_SALES_VALUE,
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
  },
};

/* PERFORMANCE
=================================*/
const performanceSet = {
  [SALES_OPPORTUNITY]: {
    area: REPORT_AREA_PERFORMANCE,
    urlCode: SALES_OPPORTUNITY,
    title: t(SALES_OPPORTUNITY_TITLE),
    description: t('sales_opportunities_report'),
    defaultChartSortOption1: REPORTS_CHART_INITIAL_KEY1,
    defaultChartSortOption2: REPORTS_CHART_INITIAL_KEY2,
    policy: intelligence_crm_reports,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: t(REPORTS_KPI_CUSTOMER_TITLE),
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: 'opportunities_count',
        name: t('opportunities'),
        prefix: '',
        suffix: '',
        includesChip: true
      },
      {
        key: 'potential_value',
        name: t('potential_value'),
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        includesChip: true
      },
      {
        key: 'allocated_to',
        name: t('rep'),
        prefix: '',
        suffix: '',
        includesChip: false
      },
    ],
    filters: [REPORT_DATE_FILTER]
  },
  [INTERACTION_OUTCOMES]: {
    area: REPORT_AREA_PERFORMANCE,
    urlCode: INTERACTION_OUTCOMES,
    title: INTERACTION_OUTCOMES_TITLE,
    description: t('number_of_interactions_each_sales_user_has_made_and_logged'),
    requestEntity: REQUEST_ENTITY_INTERACTION_SUMMARY,
    policy: intelligence_crm_reports,
  },
  [INTERACTION_OUTCOMES_DRILL]: {
    area: REPORT_AREA_PERFORMANCE,
    urlCode: INTERACTION_OUTCOMES_DRILL,
    title: INTERACTION_OUTCOMES_TITLE,
    description: t('number_of_interactions_each_sales_user_has_made_and_logged'),
    requestEntity: REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL,
  },
  [INTERACTION_SPEND_SUMMARY]: {
    area: REPORT_AREA_PERFORMANCE,
    urlCode: INTERACTION_SPEND_SUMMARY,
    title: INTERACTION_SPEND_SUMMARY_TITLE,
    description: t('view_total_sales_activity_for_each_customer_rep_or_parent_account'),
    requestEntity: REQUEST_ENTITY_INTERACTION_SPEND_SUMMARY,
    policy: intelligence_crm_reports,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_CUSTOMER_NAME_TITLE,
        prefix: '',
        suffix: '',
      },
      {
        key: REPORTS_KPI_ACCOUNT_NUMBER,
        name: REPORTS_KPI_ACCOUNT_NUMBER_TITLE,
        prefix: '',
        suffix: '',
      },
      {
        key: REPORTS_KPI_TOTAL_INTERACTIONS,
        name: REPORTS_KPI_TOTAL_INTERACTIONS_TITLE,
        prefix: '',
        suffix: '',
      },
      {
        key: 'total_sales',
        name: t('total_sales_value'),
        prefix: '',
        suffix: '',
      },
      {
        key: 'average_sales_per_interaction',
        name: t('average_sales_per_interaction'),
        prefix: '',
        suffix: '',
      },
      {
        key: 'average_interactions_per_day',
        name: t('average_interactions_per_day'),
        prefix: '',
        suffix: '',
      },
      {
        key: 'total_sales_ytd',
        name: t('year_to_date'),
        prefix: '',
        suffix: '',
      },
    ],
    defaultSortHeader: 'total_sales',
    defaultSortOption: REPORTS_SORT_DESCENDING_KEY,
  },
  [PERFORMANCE_SNAPSHOT]: {
    disabled: true,
    area: REPORT_AREA_PERFORMANCE,
    urlCode: PERFORMANCE_SNAPSHOT,
    title: PERFORMANCE_SNAPSHOT_TITLE,
    description: t('everything_you_need_to_know_about_your_performance'),
    policy: [intelligence_crm_reports, ...snapshotPolicies]
  },
  [BRANCH_SNAPSHOT]: {
    disabled: true,
    area: REPORT_AREA_PERFORMANCE,
    urlCode: BRANCH_SNAPSHOT,
    title: BRANCH_SNAPSHOT_TITLE,
    description: t('everything_you_need_to_know_about_your_performance_by_branch'),
    policy: [intelligence_crm_reports, ...snapshotPolicies]
  },
};

/* ALERTS
=================================*/
const alertSet = {
  [PRODUCT_COMMUNITY_INSIGHTS]: {
    urlCode: NEW_ALERT_ROUTE,
    area: REPORT_AREA_ALERTS,
    linkText: t('open_the_alert_builder'),
    title: t(ALERT_BUILDER_INSIGHTS_TITLE),
    description: t('put_an_end_to_missed_sales_by_setting_alerts_to_customer_behaviours'),
  },
};

const analysisReportData = {
  title: PRODUCT_GROUP_PURCHASE_ANALYSIS_TITLE,
  tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
  dateRangeNumber: 1,
  visualInput: [
    {
      key: REPORTS_KPI_DESCRIPTION,
      name: REPORTS_KPI_DESCRIPTION_TITLE,
      prefix: '',
      suffix: '',
      includesChip: false
    },
    {
      key: 'total',
      name: t('total_purchase_value'),
      prefix: CURRENCY_SYMBOL,
      suffix: '',
      includesChip: true
    },
    {
      key: 'purchase_share',
      name: t('total_purchase_share'),
      prefix: '',
      suffix: PERCENT_SYMBOL,
      includesChip: true
    },
    {
      key: 'average_customer_spend',
      name: t('average_customer_spend'),
      prefix: '',
      suffix: PERCENT_SYMBOL,
      includesChip: true
    },
  ],
  requestEntity: REQUEST_ENTITY_PRODUCT_GROUP_PURCHASE_ANALYSIS,
  policy: intelligence_products
};

export const snapshotReportSet = {
  [TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE]: {
    urlCode: TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE,
    title: TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE_TITLE,
    chartComponent: DATA_VISUALISATION_PIE_CHART,
    chartKey: REPORTS_KPI_REVENUE,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_CODE,
        name: REPORTS_KPI_CODE_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES_GROUP,
    policy: intelligence_products,
  },
  [TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED]: {
    urlCode: TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED,
    title: TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED_TITLE,
    chartComponent: DATA_VISUALISATION_PIE_CHART,
    chartKey: REPORTS_KPI_AMOUNT,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_CODE,
        name: REPORTS_KPI_CODE_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_AMOUNT,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES_GROUP,
    policy: intelligence_products,
  },
  [TOP_PRODUCTS_BY_PURCHASE_VALUE]: {
    urlCode: TOP_PRODUCTS_BY_PURCHASE_VALUE,
    title: TOP_PRODUCTS_BY_PURCHASE_VALUE_TITLE,
    chartComponent: DATA_VISUALISATION_PIE_CHART,
    chartKey: REPORTS_KPI_REVENUE,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_CODE,
        name: REPORTS_KPI_CODE_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_REVENUE,
        name: REPORTS_KPI_REVENUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES,
    policy: intelligence_products 
  },
  [TOP_PRODUCTS_BY_PURCHASE_QUANTITY]: {
    urlCode: TOP_PRODUCTS_BY_PURCHASE_QUANTITY,
    title: TOP_PRODUCTS_BY_PURCHASE_QUANTITY_TITLE,
    chartComponent: DATA_VISUALISATION_PIE_CHART,
    chartKey: REPORTS_KPI_AMOUNT,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_CODE,
        name: REPORTS_KPI_CODE_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_PRODUCT_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_AMOUNT,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_PRODUCTS_SALES,
    policy: intelligence_products 
  },
  [PRODUCTS_PURCHASED_FREQUENTLY]: {
    urlCode: PRODUCTS_PURCHASED_FREQUENTLY,
    title: PRODUCTS_PURCHASED_FREQUENTLY_TITLE,
    chartComponent: DATA_VISUALISATION_PIE_CHART,
    chartKey: REPORTS_KPI_COUNT,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_CODE,
        name: REPORTS_KPI_CODE_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_DESCRIPTION,
        name: REPORTS_KPI_DESCRIPTION_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_COUNT,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_FREQUENT_PURCHASES,
    policy: intelligence_insights,
  },
  [PRODUCT_GROUP_PURCHASE_ANALYSIS]: {
    ...analysisReportData,
    urlCode: PRODUCT_GROUP_PURCHASE_ANALYSIS,
    chartComponent: DATA_VISUALISATION_LINE_BAR,
    report: intelligence_products
  },
  [PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART]: {
    ...analysisReportData,
    urlCode: PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART,
    chartComponent: DATA_VISUALISATION_PIE_CHART,
    chartKey: 'purchase_share',
  },
  [MONTHLY_PURCHASES_BY_VALUE]: {
    urlCode: MONTHLY_PURCHASES_BY_VALUE,
    title: MONTHLY_PURCHASES_BY_VALUE_TITLE,
    chartComponent: DATA_VISUALISATION_MULTI_BAR,
    tableComponent: TABLE_TYPE_TRENDS,
    dateRangeNumber: 2,
    prefix: CURRENCY_SYMBOL,
    requestEntity: REQUEST_ENTITY_TRENDS,
    policy: intelligence_enquiries
  },
  [MONTHLY_PURCHASES_BY_QUANTITY]: {
    urlCode: MONTHLY_PURCHASES_BY_QUANTITY,
    title: MONTHLY_PURCHASES_BY_QUANTITY_TITLE,
    chartComponent: DATA_VISUALISATION_MULTI_BAR,
    tableComponent: TABLE_TYPE_TRENDS,
    dateRangeNumber: 2,
    prefix: '',
    requestEntity: REQUEST_ENTITY_TRENDS,
    policy: intelligence_enquiries
  },
  [LATEST_PURCHASES_BY_VALUE]: {
    urlCode: LATEST_PURCHASES_BY_VALUE,
    title: LATEST_PURCHASES_BY_VALUE_TITLE,
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_CODE,
        name: REPORTS_KPI_CODE_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_DESCRIPTION,
        name: REPORTS_KPI_DESCRIPTION_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_VALUE,
        name: REPORTS_KPI_VALUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_LATEST_PURCHASES,
    policy: intelligence_insights,
  },
  [LATEST_PURCHASES_BY_QUANTITY]: {
    urlCode: LATEST_PURCHASES_BY_QUANTITY,
    title: LATEST_PURCHASES_BY_QUANTITY_TITLE,
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_CODE,
        name: REPORTS_KPI_CODE_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_DESCRIPTION,
        name: REPORTS_KPI_DESCRIPTION_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_QUANTITY,
        name: REPORTS_KPI_QUANTITY_TITLE,
        prefix: '',
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_LATEST_PURCHASES,
    policy: intelligence_insights,
  },
  [PURCHASES_VS_GAPS_BY_PURCHASE_VALUE]: {
    urlCode: PURCHASES_VS_GAPS_BY_PURCHASE_VALUE,
    title: PURCHASES_VS_GAPS_BY_PURCHASE_VALUE_TITLE,
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_MATRIX,
    dateRangeNumber: 1,
    requestEntity: REQUEST_ENTITY_SALES_VS_GAPS,
    policy: intelligence_enquiries
  },
  [PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY]: {
    urlCode: PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY,
    title: PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY_TITLE,
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_MATRIX,
    dateRangeNumber: 1,
    requestEntity: REQUEST_ENTITY_SALES_VS_GAPS,
    policy: intelligence_enquiries
  },
  [VARIANCE_ENQUIRY_BY_PURCHASE_VALUE]: {
    urlCode: VARIANCE_ENQUIRY_BY_PURCHASE_VALUE,
    title: VARIANCE_ENQUIRY_BY_PURCHASE_VALUE_TITLE,
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_VARIANCE,
    dateRangeNumber: 2,
    requestEntity: REQUEST_ENTITY_VARIANCE,
    policy: intelligence_enquiries,
  },
  [VARIANCE_ENQUIRY_BY_QUANTITY]: {
    urlCode: VARIANCE_ENQUIRY_BY_QUANTITY,
    title: VARIANCE_ENQUIRY_BY_QUANTITY_TITLE,
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_VARIANCE,
    dateRangeNumber: 2,
    requestEntity: REQUEST_ENTITY_VARIANCE,
    policy: intelligence_enquiries,
  },
  [ACCOUNTS_ANALYSIS]: {
    urlCode: ACCOUNTS_ANALYSIS,
    title: ACCOUNTS_ANALYSIS_TITLE,
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: REPORTS_KPI_NAME,
        name: REPORTS_KPI_NAME_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_ACCOUNT_NUMBER,
        name: REPORTS_KPI_ACCOUNT_NUMBER_TITLE,
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_VALUE,
        name: REPORTS_KPI_VALUE_TITLE,
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_ACCOUNTS_ANALYSIS,
    policy: intelligence_insights
  },
  [TRANSACTIONS_ANALYSIS]: {
    urlCode: TRANSACTIONS_ANALYSIS,
    title: t(TRANSACTIONS_ANALYSIS_TITLE),
    chartComponent: DATA_VISUALISATION_TABLE_ONLY,
    tableComponent: TABLE_TYPE_TEMPLATE_REPORT,
    dateRangeNumber: 1,
    visualInput: [
      {
        key: 'dimension_value',
        name: t('dimension_value'),
        prefix: '',
        suffix: '',
        includesChip: false
      },
      {
        key: REPORTS_KPI_VALUE,
        name: t(REPORTS_KPI_VALUE_TITLE),
        prefix: CURRENCY_SYMBOL,
        suffix: '',
        includesChip: true
      },
      {
        key: REPORTS_KPI_QUANTITY,
        name: t(REPORTS_KPI_QUANTITY_TITLE),
        prefix: '',
        suffix: '',
        includesChip: true
      }
    ],
    requestEntity: REQUEST_ENTITY_TRANSACTIONS_ANALYSIS,
    policy: intelligence_insights
  },
};

export const inputSets = {
  ...templateReportSet,
  ...enquirySet,
  ...performanceSet,
  ...alertSet,
  ...snapshotReportSet,

  /* PEOPLE
  =================================*/
  [SALES_REP_LEADERBOARD]: {
    area: REPORT_AREA_PEOPLE,
    title: t(SALES_REP_LEADERBOARD_TITLE),
    description: t('who_s_hot_who_s_not'),
  },

  [HIGH_REVENUE_SELLERS]: {
    area: REPORT_AREA_PEOPLE,
    title: t(HIGH_REVENUE_SELLERS_TITLE),
    description: t('who_generates_the_most_revenue_per_interaction'),
  },

  [MOST_IMPROVED]: {
    area: REPORT_AREA_PEOPLE,
    title: t(MOST_IMPROVED_TITLE),
    description: t('who_improved_the_most_this_month'),
  },

  [REP_PRODUCTIVITY]: {
    area: REPORT_AREA_PEOPLE,
    title: t(REP_PRODUCTIVITY_TITLE),
    description: t('who_s_spent_the_most_time_selling_for_high_low_return'),
  },

  [REPS_WITH_GROWTH_POTENTIAL]: {
    area: REPORT_AREA_PEOPLE,
    title: t(REPS_WITH_GROWTH_POTENTIAL_TITLE),
    description: t('who_has_the_least_interactions_but_made_the_most_profit'),
  },

  [REPS_THAT_NEED_SUPPORT]: {
    area: REPORT_AREA_PEOPLE,
    title: t(REPS_THAT_NEED_SUPPORT_TITLE),
    description: t('who_s_leaking_sales'),
  },

  [SALES_OPPORTUNITY_DEMO]: {
    area: REPORT_AREA_PEOPLE,
    title: t(SALES_OPPORTUNITY_DEMO_TITLE),
    description: t('sales_opportunities_report'),
  },

  [INTERACTION_OUTCOMES_DEMO]: {
    area: REPORT_AREA_PEOPLE,
    title: t(INTERACTION_OUTCOMES_DEMO_TITLE),
    description: t('interaction_outcomes_report'),
  }
};

/* eslint-disable no-unused-vars */
import {
  GET_STAGES,
  CLEAR_STAGES,
  PATCH_STAGES,
  SET_STAGES,
  ADD_STAGE,
  MOVE_STAGE,
  EDIT_STAGE,
  DELETE_STAGE
} from '@/admin/store/actionType';
import { SET_ITEMS } from '@/shared/store/actionType';
import { httpRequest, tAdmin as t } from '@sales-i/utils';

const GET_STAGES_LOADING = 'GET_STAGES_LOADING';
const GET_STAGES_ERROR = 'GET_STAGES_ERROR';
const endpointBasePath = 'config/schema/opportunities/stages';

// getters
export const stagesGetters = {
  stageOptions: (state) => {
    return state.stages 
      ? (state.stages.data || []).map(s => ({text: s.name, value: s.id})):
      [];
  },
  itemsForAPI: (state) => state.stages.data.map((x, index) => {
    const {
      id,
      name,
      value = name,
      score = 0,
      colour = 'white',
    } = x;

    return {  
      name,
      value,
      order_pos: index + 1,
      score,
      colour,
      ...(id > 0 ? {id:+id} : {})
    };
  })
};

// mutations
export const stagesMutations = {
  [GET_STAGES_LOADING]: (state) => {
    state.stages.loaded = false;
    state.stages.loading = true;
    state.stages.error = undefined;
  },
  [GET_STAGES]: (state, data) => {
    state.stages.data = data;
    state.stages.loaded = true;
    state.stages.loading = false;
    state.stages.error = undefined;
  },
  [GET_STAGES_ERROR]: (state, error) => {
    state.stages.loaded = false;
    state.stages.loading = false;
    state.stages.error = error;
  },
  [CLEAR_STAGES]: (state) => {
    state.stages.data = [];
    state.stages.loaded = false;
    state.stages.loading = false;
    state.stages.error = undefined;
  },
  [DELETE_STAGE]: (state, id) => {
    state.stages.data = state.stages.data.filter(s => s.id !== id);
    state.stages.loading = false;
  },
  [ADD_STAGE]: (state, index) => {
    state.stages.minId = state.stages.minId - 1; 
    state.stages.data.splice(index, 0, {
      id: state.stages.minId
    });
  },
  [MOVE_STAGE]: (state, {from, to}) => {
    if (from === to) return;
    const movedItem = state.stages.data.splice(from, 1)[0];
    state.stages.data.splice(to, 0, movedItem);
  },
  [EDIT_STAGE]: (state, {index, fieldName, value}) => {
    if (!fieldName || !(index >= 0)) return;
    state.stages.data[index][fieldName] = value;
    state.stages.data = [...state.stages.data];
  },
};

// actions
export const stagesActions = {
  [GET_STAGES]: async ({ commit, }) => {
    try {
      commit(GET_STAGES_LOADING);
      const now = new Date().getTime();
      // fix for potential caching issue
      const urlWithTimestamp = `${endpointBasePath}?now=${now}`;
      const data = await httpRequest('get', urlWithTimestamp, {}, {version: '2',});
      commit(GET_STAGES, data);
      return true;
    } catch (error) {
      commit(GET_STAGES_ERROR, error);
      return false;
    }
  },
  [SET_STAGES]: async ({ commit, }, data) => {
    try {
      commit(GET_STAGES_LOADING);
      commit(GET_STAGES, data);
      return true;
    } catch (error) {
      commit(GET_STAGES_ERROR, error);
      return false;
    }
  },
  [PATCH_STAGES]: async ({ commit, getters, dispatch}, { showSuccessMessage = true } = {}) => {
    try {
      commit(GET_STAGES_LOADING);
      const data = await httpRequest('patch', endpointBasePath, getters.itemsForAPI, {version: '2',});
      if (showSuccessMessage) {
        dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t('stages_saved'),
          selfDismiss: true,
        }, {root: true});
      }
      dispatch(GET_STAGES);
      return true;
    } catch (error) {
      commit(GET_STAGES_ERROR, error);
      return false;
    }
  },
  [CLEAR_STAGES]: ({ commit, }) => {
    commit(CLEAR_STAGES);
  },
  [ADD_STAGE]: ({ commit, }, index) => {
    commit(ADD_STAGE, index + 1);
  },
  [DELETE_STAGE]: async ({ commit, }, { id, to_stage_id }) => {
    try {
      commit(GET_STAGES_LOADING);
      if (!id || !to_stage_id) throw t('no_stage_id_or_to_stage_provided');
      await httpRequest('delete', `${endpointBasePath}/${id}`, { to_stage_id });
      commit(DELETE_STAGE, id);
      return true;
    } catch (error) {
      console.error(error);
      commit(GET_STAGES_ERROR, error);
      return false;
    }
  },
  [MOVE_STAGE]: async ({ commit, dispatch }, { from, to }) => {
    commit(MOVE_STAGE, {from, to});
    await dispatch(PATCH_STAGES, { showSuccessMessage: false });
  },
  [EDIT_STAGE]: ({ commit, }, {index, fieldName, value}) => {
    commit(EDIT_STAGE, {index, fieldName, value});
  },
};

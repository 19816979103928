<template>
  <CustomCard
    purpose="reversed"
    class="details-container"
  >
    <LoadingOverlay
      v-if="communities.loading"
      style="border-radius: var(--border-radius-1)"
    />
    <h3>{{ t('communities') }}</h3>

    <div
      v-if="communityList.length > 0"
      role="list"
      class="list list-card"
    >
      <CRMCard
        v-for="(item, index) in communityList"
        :key="index"
        :on-click="() => openItem(item)"
        :title="item.community_name"
        @open="() => openItem(item)"
      />
    </div>
  </CustomCard>
</template>

<script type="text/javascript">
import { tAdmin as t } from '@sales-i/utils';
import { GET_ALL } from '@/shared/store/actionType';
import { mapActions, mapState } from 'vuex';
import { CustomCard, CRMCard, LoadingOverlay } from '@sales-i/dsv3';

export default {
  name: 'CommunitiesListView',
  components: {
    CustomCard,
    CRMCard,
    LoadingOverlay,
  },
  emits: ['openItem'],
  computed: {
    ...mapState({
      communities: state => state.admin.communities,
    }),
    communityList() {
      return this.communities.data.filter(o => o.community_code !== '');
    },
  },
  mounted() {
    this.getAllCommunities();
  },
  methods: {
    t,
    ...mapActions({
      getAllCommunities: `admin/communities/${GET_ALL}`,
    }),
    openItem(item) {
      this.$emit('openItem', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  position: relative;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none;

  .list {
    min-height: 175px;
  }

  :deep(.card-title) {
    word-break: break-word;
    text-wrap: wrap;
  }
}
</style>

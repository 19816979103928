<template>
  <div v-if="permissions.read">
    <BufferImage
      v-if="tags.loading"
      color="var(--colour-utility-black)"
      float="center"
    />
    <TagChip
      v-for="item in displayTags"
      :key="item.text"
      :show-action="permissions.delete"
      @click="removeTag(item.text)"
    >
      <span>{{ item.text }}</span>
    </TagChip>
    <CustomInput
      v-if="permissions.create"
      :id="id"
      :for="id"
      :label="t(label)"
      :placeholder="t(placeholder)"
      :max-length="maxLength"
      :required="required"
      :disabled="disabled"
      :value="value"
      :error="error || childError ? true : null"
      :error-message="t(errorMessage)"
      :icon-name="icon.name"
      :icon-color="icon.color"
      :icon-align="icon.align"
      :items="allTags"
      list-name="tags-list"
      autocomplete="off"
      type="text"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @keydown="handleKeydown"
      @keyup="handleKeyup"
    />
    <CustomButton
      v-if="permissions.create"
      class="add-tag-btn"
      purpose="neutral"
      small
      @on-click="addTag(value)"
    >
      {{ t('add_tag') }}
    </CustomButton>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { BufferImage, CustomInput, CustomButton, TagChip } from '@sales-i/dsv3';
import { tAdmin as t } from '@sales-i/utils';

import usePermissions from '@/shared/composables/usePermissions';
import { tags as tagsPerm } from '@/shared/store/data/policies';
import { GET_ALL, GET_BY_ENTITY } from '@/shared/store/actionType';

const { getPermissions } = usePermissions();
const permissions = getPermissions(tagsPerm);

const store = useStore();

const props = defineProps({
  id: {
    type: String,
    default: 'add_tag',
  },
  label: {
    type: String,
    default: 'add_tag',
  },
  placeholder: {
    type: String,
    default: 'enter_a_tag',
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: 'please_add_a_tag',
  },
  icon: {
    type: Object,
    default: () => ({}),
  },
  entity: {
    type: String,
    default: '',
  },
  entityId: {
    type: [String, Number],
    default: '',
  },
});

const emit = defineEmits(['keydown', 'keyup', 'input', 'focus', 'blur']);
const value = ref('');
const maxLength = 30;
const newTags = ref([]);
const deletedTags = ref([]);
const childError = ref(false);

const tags = computed(() => store.state.tags.all);
const existingTags = computed(() => store.state.tags.entity.data.map(item => ({ text: item.tag })));

const displayTags = computed(() => {
  let arr = [];
  existingTags.value.forEach(item => {
    if (!arr.find(tag => tag.text === item.text)) {
      arr.push(item);
    }
  });
  newTags.value.forEach(item => {
    let exists = arr.find(tag => tag.text === item.text);
    if (!exists) {
      arr.push(item);
    }
  });
  deletedTags.value.forEach(deleted => {
    arr = arr.filter(item => item.text !== deleted.text);
  });
  return arr.sort((a, b) => (a.text > b.text ? 1 : -1));
});

const allTags = computed(() => {
  let allTags = [];
  tags.value.data.forEach(item => {
    let isDisplayed = displayTags.value.find(display => display.text === item.tag);
    if (!isDisplayed) {
      allTags.push({ text: item.tag });
    }
  });
  return allTags.sort((a, b) => (a.text > b.text ? 1 : -1));
});

onMounted(() => loadTags());
const getAll = params => store.dispatch(`tags/${GET_ALL}`, params);
const getByEntity = params => store.dispatch(`tags/${GET_BY_ENTITY}`, params);

function loadTags() {
  getAll({
    limit: 10000, // Set to ridiculously high number (for now) to get all tags
  });
  if (props.entity && props.entityId) {
    getByEntity({
      entity: props.entity,
      id: props.entityId,
    });
  }
}

function handleKeydown(event) {
  if (event.code === 'Space' || event.code === 'Enter') {
    event.preventDefault();
  }
  emit('keydown', event);
}

function handleKeyup(event) {
  if (event.key === 'Enter') {
    addTag(value.value);
  }
  emit('keyup', event);
}

function handleInput(nValue) {
  childError.value = false;
  nValue = nValue.toUpperCase();
  value.value = nValue;
  emit('input', {
    isInputting: true,
    tagsToAdd: getTagsToAdd(value.value),
    tagsToDelete: getTagsToDelete(),
  });
}

function handleFocus() {
  emit('focus');
}

function handleBlur() {
  emit('blur');
}

function addTag(tag = '') {
  childError.value = !tag;
  if (tag) {
    let tagExists = existingTags.value.find(item => item.text === tag);
    deletedTags.value = deletedTags.value.filter(item => item.text !== tag);
    if (!tagExists) {
      newTags.value = newTags.value.filter(item => item.text !== tag);
      newTags.value.push({ text: tag });
    }
    value.value = '';
    emit('input', {
      isInputting: false,
      tagsToAdd: getTagsToAdd(),
      tagsToDelete: getTagsToDelete(),
    });
  }
}

function removeTag(tag = '') {
  if (!permissions.delete) return;
  deletedTags.value.push({ text: tag });
  newTags.value = newTags.value.filter(item => item.text !== tag);
  emit('input', {
    isInputting: value.value !== '',
    tagsToAdd: getTagsToAdd(value.value),
    tagsToDelete: getTagsToDelete(),
  });
}

function getTagsToAdd(value) {
  let tagsToAdd = [];
  newTags.value.forEach(item => {
    let tagExists = existingTags.value.find(existing => existing.text === item.text);
    if (!tagExists) {
      tagsToAdd.push({ tag: item.text });
    }
  });
  if (value) {
    let valueExists = existingTags.value.find(existing => existing.text === value);
    if (!valueExists) {
      tagsToAdd.push({ tag: value });
    }
  }
  return tagsToAdd;
}

function getTagsToDelete() {
  let tagsToDelete = [];
  deletedTags.value.forEach(item => {
    let tagExists = existingTags.value.find(existing => existing.text === item.text);
    if (tagExists) {
      tagsToDelete.push({ tag: item.text });
    }
  });
  return tagsToDelete;
}
</script>

<style lang="scss" scoped>
.add-tag-btn {
  display: block;
  margin-left: auto;
}
</style>
